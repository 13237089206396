import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, QueryList, ViewChildren } from '@angular/core';
import { Options, ChangeContext, PointerType } from '@angular-slider/ngx-slider';
import { LoginService } from '../../services/login.service';
import { BackenddbService } from '../../services/backenddb.service';
import { FrontenddbService } from '../../services/frontenddb.service';
import { DatapassService } from '../../services/datapass.service';
import { Router, ActivatedRoute } from '@angular/router';

import { faArrowDown, faStar, faCircle, faQuestion, faQuestionCircle, faPowerOff, faTrash, faShoppingCart, faRecycle, faCog, faArrowCircleRight, faAngleDoubleLeft, faAngleDoubleRight, faAngleUp, faAngleDown, faAnchor, faLifeRing, faUndo, faCartPlus, faMoneyBill, faMoneyBillWave, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { UntypedFormGroup,  UntypedFormBuilder, UntypedFormArray, UntypedFormControl, Validators  } from '@angular/forms';
import {FilterPipe} from '../../modal/filter.pipe';

@Component({
  selector: 'app-exerciserepository',
  templateUrl: './exerciserepository.component.html',
  styleUrls: ['./exerciserepository.component.css']
})
export class ExerciserepositoryComponent implements OnInit {

	//icon
	faArrowDown = faArrowDown;
	faStar = faStar;
	faCircle = faCircle;
	faQuestionCircle = faQuestionCircle;
	faQuestion = faQuestion;
	faPowerOff = faPowerOff;
	faTrash = faTrash;
	faShoppingCart = faShoppingCart;
	faRecycle = faRecycle;
	faCog = faCog;
	faArrowCircleRight = faArrowCircleRight;
	faAngleDoubleLeft = faAngleDoubleLeft;
	faAngleDoubleRight = faAngleDoubleRight;
	faAngleUp = faAngleUp;
	faAngleDown = faAngleDown;
	faAnchor = faAnchor;
	faLifeRing = faLifeRing;
	faUndo = faUndo;
	faCartPlus = faCartPlus;
	faMoneyBill = faMoneyBill;
	faMoneyBillWave = faMoneyBillWave;
	faUserCircle = faUserCircle;

	collBtnStatus:boolean = false;
	isCollapsed:boolean = false;
	//store teaming
	search_keyword:string = '';
	teamingList = [];
	killChainList = [];
	difficultyList = ['Low','Medium','High'];
	difficultyClass = ['lowc','mediumc','highc'];
	exe_type = ['Instructor-Led','Self-Paced'];
	exe_val:string = '';
	exerciseData = [];
	newsList = [];
	start_limit:number = 0;
	length:number = 16;
	killChainVal:any = [];

	/* rating filter */
	minValue:string = '1';
	maxValue:string = '5';
	options: Options = {
	  floor: 1,
	  ceil: 5,
	  showTicksValues: true
	};
	user_id:string='';
	//load more btn
	load_more_btn:boolean = true;
	//loader 
	loader:boolean = false;
	logText: string = '';
	toggletxt:any='Show Filter';

	// for store added exercise
	added_basket = [];

	exefilterfrm: UntypedFormGroup;
	
	/* show hide button */
	isShow = false;
	toggleDisplay() {
		this.isShow = !this.isShow;
		if(this.toggletxt =='Show Filter'){
            this.toggletxt='Hide Filter';
        }
        else{
            this.toggletxt = 'Show Filter';
        }
	}
	/* End */

	server_url:string;	

	setting = [];
	credit_system:string = 'Inactive';
	
	/*breadcrumbs array */
	current_url_array = [];
    form_title:string = 'Exercises';
	
	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private BackenddbService: BackenddbService,
		private loginService: LoginService,   
		private FrontenddbService: FrontenddbService,  
		private fb:UntypedFormBuilder,
		private ds: DatapassService 

	) 
	{		
		this.server_url = this.loginService.getServerUrl();
		this.user_id = this.loginService.getUserId();
		
		if(this.user_id == null){
			localStorage.clear();
		}
        //Here get teaming list
		this.BackenddbService.getTeamingList().subscribe((data:any) => {
			this.teamingList = Array.from(Object.keys(data), k=>data[k]);
		});	

		this.BackenddbService.getData('candidate-api/cyber-kill-chain-list').subscribe((data:any) => {
			console.log(data)
			this.killChainList = Array.from(Object.keys(data), k=>data[k]);
		});	

		//Here get news	
		const formData = new FormData();
		this.FrontenddbService.getNews(formData).subscribe(
			res => {
				this.newsList = Array.from(Object.keys(res), k=>res[k]);
			});
	}
	
	ngOnInit(): void {

		/*breadcrumbs array */
		this.current_url_array = [
			{'slug':"",'label':'Exercises'}
		];

        //Here set form group
		this.exefilterfrm = this.fb.group({
			teaming: this.fb.array([]),
			difficulty: this.fb.array([]),
			kill_chain: this.fb.array([]),
			exe_type: [],
			search:[]
		  }); 
		this.loadExerciseRepository();  
		
		if(window.screen.width < 767) 
		{
			this.isShow = true;
		}
		else
		{
			this.isShow = false;
		}	
		this.loadSubcData();	  
	}

	loadSubcData()
	{
	  this.ds.getData().subscribe(x => { 
		if(x[0]=='set_permission')
		{
		   this.set_permission(x[1]);
		}
	  });
	}

	// load exercise
	loadExerciseRepository()
	{
		this.loader = true;
		const formData = new FormData();
		let frmVal = this.exefilterfrm.value;
		formData.append('rating',this.maxValue);
		formData.append('teaming',frmVal.teaming);
		formData.append('difficulty_level',frmVal.difficulty);
		formData.append('kill_chain',frmVal.kill_chain);
		formData.append('exe_type',this.exe_val);
		formData.append('search', this.search_keyword);
		formData.append('order_by', 'ex.id');
		let num = this.start_limit;//number
		var start_limit = num.toString(); // convert to string
		formData.append('start_limit', start_limit);
		let num1 = this.length;//number
        var length = num1.toString(); // convert to string
		formData.append('length', length);

		// Here get exercise 
		this.BackenddbService.postData('candidate-api/get-exercise-repository',formData).subscribe(
			res => {
				setTimeout( () => { this.loader = false; }, 1000 );
				res.forEach((myObject:any, index:any) => {
					this.exerciseData.push(res[index]);
				});
				if(res.length>=16)
				{
					this.load_more_btn = true;
				}else
				{
					this.load_more_btn = false;
				}
			});
	}

	// on rating change
	onUserChangeEnd(changeContext: ChangeContext): void {
		this.exerciseData = [];
		this.start_limit = 0;
		this.loadExerciseRepository();
	}

	// on teaming change
	onChange(team:string, isChecked: boolean)
	{
    	const teamFormArray = <UntypedFormArray>this.exefilterfrm.controls.teaming;
    	if (isChecked) {
			teamFormArray.push(new UntypedFormControl(team));
		} else {
		  let index = teamFormArray.controls.findIndex(x => x.value == team)
		  teamFormArray.removeAt(index);
		}
		this.exerciseData = [];
		this.start_limit = 0;
		this.loadExerciseRepository();
	  }

	// on difficulty level change
	  onDiffChange(diffy:string, isChecked: boolean)
	  {
		const diffyFormArray = <UntypedFormArray>this.exefilterfrm.controls.difficulty;
		if (isChecked) {
			diffyFormArray.push(new UntypedFormControl(diffy));
		} else {
		  let index = diffyFormArray.controls.findIndex(x => x.value == diffy)
		  diffyFormArray.removeAt(index);
		}
		this.exerciseData = [];
		this.start_limit = 0;
		this.loadExerciseRepository();
	  }

	  changeArrow()
	  {
		this.collBtnStatus = !this.collBtnStatus
	  }

	// on difficulty level change
	onKillChainChange(diffy:string, isChecked: boolean)
	{
	  const killChainArray = <UntypedFormArray>this.exefilterfrm.controls.kill_chain;
	  if (isChecked) {
		killChainArray.push(new UntypedFormControl(diffy));
		this.killChainVal.push(diffy)
	  } else {
		let index = killChainArray.controls.findIndex(x => x.value == diffy)
		killChainArray.removeAt(index);
		this.killChainVal.splice(index,1);
	  }
	  this.exerciseData = [];
	  this.start_limit = 0;
	  this.loadExerciseRepository();
	}
	  onExTypeChange(diffy:string)
	  {
		this.exe_val = diffy
		this.exerciseData = [];
		this.start_limit = 0;
		this.loadExerciseRepository();
	  }

	  

	// on load more click  
	loadMore()
	{
		this.start_limit = this.start_limit+this.length; 
		this.loadExerciseRepository(); 
	}
	
	search(data:any)
	{
		this.exerciseData = [];
		this.start_limit = 0;
		this.search_keyword = data.search;
		this.loadExerciseRepository(); 
	}
	
	resetForm() {
		window.location.reload();
    }

	onKeyUpEvent(data:any)
	{
		if(data.search=="")
		{
		this.exerciseData = [];
		this.start_limit = 0;
		this.search_keyword = data.search;
		this.loadExerciseRepository();
		}
	}

	get_added_basket(basket:any)
	{
		this.added_basket = basket;
	} 

	get_setting(settings:any)
	{
		this.setting = settings;
		this.setting.forEach( (setObj:any, index:any) => {
			if(setObj.skey=="credit_system")
			{
				var svalue = JSON.parse(setObj.svalue);
				if(svalue[0].status=='Active')
				{
					this.credit_system = 'Active';
				}
			}
	   });
	}

	set_permission(data:any)
	{
		if(data.permission.enroll=='Denied')
		{
			this.router.navigate(['/']);
		}
	}

	preloadData()
	{
	    this.ds.sendData('get_permission');
	}

	toggleCollapse() 
	{
		this.isCollapsed = !this.isCollapsed;
	}


}
