  export function getTimeStamp() 
  {
       return (new Date()).getTime();
  }
  export function copyUrl(val: string){
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }


  export function convertToString(inputDateString)
  {
      const parsedDate = new Date(inputDateString);

      // Extract the date and time components
      const year = parsedDate.getFullYear();
      const month = String(parsedDate.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed
      const day = String(parsedDate.getDate()).padStart(2, "0");
      const hours = String(parsedDate.getHours()).padStart(2, "0");
      const minutes = String(parsedDate.getMinutes()).padStart(2, "0");
      const seconds = String(parsedDate.getSeconds()).padStart(2, "0");

      // Create the formatted date string
      return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  }

  export function convertDateToTimeStamp(dateString:any)
  {
    let datePart:any = []
    let timePart:any = []
    if(typeof dateString == 'string')
    {
      [datePart, timePart] = dateString.split(' ');
    }else
    {
      dateString = convertToString(dateString);
      [datePart, timePart] = dateString.split(' ');
    }

    const [day, month, year, hours, minutes, seconds] = [...datePart.split('-'), ...timePart.split(':')];
    return new Date(year, month - 1, day, hours, minutes, seconds).getTime();
  }

  export function numberToRankText(number:any) {
    const lastDigit = number % 10;
    const secondLastDigit = Math.floor((number % 100) / 10);
    if (lastDigit === 1 && secondLastDigit !== 1) {
      return number + "st rank";
    } else if (lastDigit === 2 && secondLastDigit !== 1) {
      return number + "nd rank";
    } else if (lastDigit === 3 && secondLastDigit !== 1) {
      return number + "rd rank";
    } else {
      return number + "th rank";
    }
  }