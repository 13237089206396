// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.regbg {       
	margin: auto;	
	display: block;
}

.regpage {
    color: #212529;
    text-align: center;    
	padding-top: 40px;
	padding-bottom: 30px;
    background-color: #fff;
}
.regform .heading{ 
    margin-bottom: 50px;
}
.regform .btn.btn-dark.btn-register{ 
    background: #737f9e; 
	width: 125px;
    margin: auto;
    display: block;
    border: 0;
}
.regform .btn.btn-dark.btn-register:hover{ 
    color: #fff;
    background: #64738f; 
    border: 0;
}
.regform .linka{ 
    color: #fff; 
}
.regform .form_lable{ 
    text-align: left !important;
}
@media only screen and (min-width: 768px) 
{
	.regform .col4 {
		/*max-width: 165px;
		padding-right: 5px;*/
		max-width: 178px;
		padding-right: 0;
	}
}
@media only screen and (max-width: 768px) 
{
	.regbg {       
		width: 100%; 
    }
	.regform .heading{ 
		margin-bottom: 30px; 
		padding: 10px 0px;
		font-size: 24px;
	}
	.regform .form_lable{ 
		margin-bottom: 0px !important;
	}
}
@media only screen and (min-device-width: 1024px) and (max-device-width: 1365px) and (orientation:portrait) 
{
	.regbg {       
		width: 100%;
    }
}
`, "",{"version":3,"sources":["webpack://./src/app/registration/registration.component.css"],"names":[],"mappings":"AAAA;CACC,YAAY;CACZ,cAAc;AACf;;AAEA;IACI,cAAc;IACd,kBAAkB;CACrB,iBAAiB;CACjB,oBAAoB;IACjB,sBAAsB;AAC1B;AACA;IACI,mBAAmB;AACvB;AACA;IACI,mBAAmB;CACtB,YAAY;IACT,YAAY;IACZ,cAAc;IACd,SAAS;AACb;AACA;IACI,WAAW;IACX,mBAAmB;IACnB,SAAS;AACb;AACA;IACI,WAAW;AACf;AACA;IACI,2BAA2B;AAC/B;AACA;;CAEC;EACC;sBACoB;EACpB,gBAAgB;EAChB,gBAAgB;CACjB;AACD;AACA;;CAEC;EACC,WAAW;IACT;CACH;EACC,mBAAmB;EACnB,iBAAiB;EACjB,eAAe;CAChB;CACA;EACC,6BAA6B;CAC9B;AACD;AACA;;CAEC;EACC,WAAW;IACT;AACJ","sourcesContent":[".regbg {       \n\tmargin: auto;\t\n\tdisplay: block;\n}\n\n.regpage {\n    color: #212529;\n    text-align: center;    \n\tpadding-top: 40px;\n\tpadding-bottom: 30px;\n    background-color: #fff;\n}\n.regform .heading{ \n    margin-bottom: 50px;\n}\n.regform .btn.btn-dark.btn-register{ \n    background: #737f9e; \n\twidth: 125px;\n    margin: auto;\n    display: block;\n    border: 0;\n}\n.regform .btn.btn-dark.btn-register:hover{ \n    color: #fff;\n    background: #64738f; \n    border: 0;\n}\n.regform .linka{ \n    color: #fff; \n}\n.regform .form_lable{ \n    text-align: left !important;\n}\n@media only screen and (min-width: 768px) \n{\n\t.regform .col4 {\n\t\t/*max-width: 165px;\n\t\tpadding-right: 5px;*/\n\t\tmax-width: 178px;\n\t\tpadding-right: 0;\n\t}\n}\n@media only screen and (max-width: 768px) \n{\n\t.regbg {       \n\t\twidth: 100%; \n    }\n\t.regform .heading{ \n\t\tmargin-bottom: 30px; \n\t\tpadding: 10px 0px;\n\t\tfont-size: 24px;\n\t}\n\t.regform .form_lable{ \n\t\tmargin-bottom: 0px !important;\n\t}\n}\n@media only screen and (min-device-width: 1024px) and (max-device-width: 1365px) and (orientation:portrait) \n{\n\t.regbg {       \n\t\twidth: 100%;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
