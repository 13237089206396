// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.basketbox
{
    background-color: #fff;
    box-shadow: 0 2px 9px 2px rgb(0 0 0 / 14%);
    padding: 18px 0;
}
.btn_blueas 
{
	display: inline-block;
}
.ed_center {
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border: 1px solid #f5f5f5;
}

.v-box .pricei {
    font-size: 20px;
    display: inline-flex;
}
.priceit {
    font-size: 25px;
}
.price-content {
	background: #edf0f2;
    border-radius: .2rem;
    padding: 20px;
}
.pricetext {
    font-family: 'OpenSans-SemiBold', sans-serif;
	font-size: 18px; 
    color: #6c757d;	
}`, "",{"version":3,"sources":["webpack://./src/app/candidate/buyvoucher/buyvoucher.component.css"],"names":[],"mappings":"AAAA;;IAEI,sBAAsB;IACtB,0CAA0C;IAC1C,eAAe;AACnB;AACA;;CAEC,qBAAqB;AACtB;AACA;IACI,mBAAmB;IACnB,uBAAuB;IACvB,sBAAsB;IACtB,yBAAyB;AAC7B;;AAEA;IACI,eAAe;IACf,oBAAoB;AACxB;AACA;IACI,eAAe;AACnB;AACA;CACC,mBAAmB;IAChB,oBAAoB;IACpB,aAAa;AACjB;AACA;IACI,4CAA4C;CAC/C,eAAe;IACZ,cAAc;AAClB","sourcesContent":[".basketbox\n{\n    background-color: #fff;\n    box-shadow: 0 2px 9px 2px rgb(0 0 0 / 14%);\n    padding: 18px 0;\n}\n.btn_blueas \n{\n\tdisplay: inline-block;\n}\n.ed_center {\n    align-items: center;\n    justify-content: center;\n    background-color: #fff;\n    border: 1px solid #f5f5f5;\n}\n\n.v-box .pricei {\n    font-size: 20px;\n    display: inline-flex;\n}\n.priceit {\n    font-size: 25px;\n}\n.price-content {\n\tbackground: #edf0f2;\n    border-radius: .2rem;\n    padding: 20px;\n}\n.pricetext {\n    font-family: 'OpenSans-SemiBold', sans-serif;\n\tfont-size: 18px; \n    color: #6c757d;\t\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
