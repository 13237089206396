// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `a.disabled {
    color: gray;
    cursor: not-allowed;
    text-decoration: underline;
}

.seen_button{
    color: #fff;
                                         font-family: 'OpenSans-SemiBold', sans-serif;
                                         background: #aeffae;
                                         padding: 4px 10px;
                                         border-radius: 8px;
                                         min-width: 100px;
                                         display: inline-block;
                                         text-align: center;
}
.unseen_button{
    color: #fff;
                                         font-family: 'OpenSans-SemiBold', sans-serif;
                                         background: #df1f1f;
                                         padding: 4px 10px;
                                         border-radius: 8px;
                                         min-width: 100px;
                                         display: inline-block;
                                         text-align: center;
}
.back_rv {
    background-color: rgb(0 0 0 / 0%) !important;
}`, "",{"version":3,"sources":["webpack://./src/app/dd-terminal/demorequestlist/demorequestlist.component.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,mBAAmB;IACnB,0BAA0B;AAC9B;;AAEA;IACI,WAAW;yCAC0B,4CAA4C;yCAC5C,mBAAmB;yCACnB,iBAAiB;yCACjB,kBAAkB;yCAClB,gBAAgB;yCAChB,qBAAqB;yCACrB,kBAAkB;AAC3D;AACA;IACI,WAAW;yCAC0B,4CAA4C;yCAC5C,mBAAmB;yCACnB,iBAAiB;yCACjB,kBAAkB;yCAClB,gBAAgB;yCAChB,qBAAqB;yCACrB,kBAAkB;AAC3D;AACA;IACI,4CAA4C;AAChD","sourcesContent":["a.disabled {\n    color: gray;\n    cursor: not-allowed;\n    text-decoration: underline;\n}\n\n.seen_button{\n    color: #fff;\n                                         font-family: 'OpenSans-SemiBold', sans-serif;\n                                         background: #aeffae;\n                                         padding: 4px 10px;\n                                         border-radius: 8px;\n                                         min-width: 100px;\n                                         display: inline-block;\n                                         text-align: center;\n}\n.unseen_button{\n    color: #fff;\n                                         font-family: 'OpenSans-SemiBold', sans-serif;\n                                         background: #df1f1f;\n                                         padding: 4px 10px;\n                                         border-radius: 8px;\n                                         min-width: 100px;\n                                         display: inline-block;\n                                         text-align: center;\n}\n.back_rv {\n    background-color: rgb(0 0 0 / 0%) !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
