// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.manual {
    min-height: 100vh;
    background: #f7f7f7;
}
`, "",{"version":3,"sources":["webpack://./src/app/candidate/exerciseconsole/exerciseconsole.component.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,mBAAmB;AACvB","sourcesContent":[".manual {\n    min-height: 100vh;\n    background: #f7f7f7;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
