// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.js .inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}
.inputfile + label {
    max-width: 80%;
    font-size: 16px;
    /* 20px */
    font-weight: 700;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
   /* padding: 0.625rem 1.25rem; */
    /* 10px 20px */
}
.no-js .inputfile + label {
    display: none;
}
/* .inputfile:focus + label,
.inputfile.has-focus + label {
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
} */


.inputfile + label svg {
    width: 1em;
    height: 1em;
    vertical-align: middle;
    fill: currentColor;
    margin-top: -0.25em;
    /* 4px */
    margin-right: 0.25em;
    /* 4px */
}
/* style 1 */
/* .inputfile-1 + label {
    color: #f1e5e6;
    background-color: #d3394c;
} */
.inputfile-1:focus + label,
.inputfile-1.has-focus + label,
.inputfile-1 + label:hover {
    color: #555;
}`, "",{"version":3,"sources":["webpack://./src/app/dd-terminal/photocrop/photocrop.component.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,UAAU;IACV,gBAAgB;IAChB,kBAAkB;IAClB,WAAW;AACf;AACA;IACI,cAAc;IACd,eAAe;IACf,SAAS;IACT,gBAAgB;IAChB,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;IACf,qBAAqB;IACrB,gBAAgB;GACjB,+BAA+B;IAC9B,cAAc;AAClB;AACA;IACI,aAAa;AACjB;AACA;;;;GAIG;;;AAGH;IACI,UAAU;IACV,WAAW;IACX,sBAAsB;IACtB,kBAAkB;IAClB,mBAAmB;IACnB,QAAQ;IACR,oBAAoB;IACpB,QAAQ;AACZ;AACA,YAAY;AACZ;;;GAGG;AACH;;;IAGI,WAAW;AACf","sourcesContent":[".js .inputfile {\n    width: 0.1px;\n    height: 0.1px;\n    opacity: 0;\n    overflow: hidden;\n    position: absolute;\n    z-index: -1;\n}\n.inputfile + label {\n    max-width: 80%;\n    font-size: 16px;\n    /* 20px */\n    font-weight: 700;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n    cursor: pointer;\n    display: inline-block;\n    overflow: hidden;\n   /* padding: 0.625rem 1.25rem; */\n    /* 10px 20px */\n}\n.no-js .inputfile + label {\n    display: none;\n}\n/* .inputfile:focus + label,\n.inputfile.has-focus + label {\n    outline: 1px dotted #000;\n    outline: -webkit-focus-ring-color auto 5px;\n} */\n\n\n.inputfile + label svg {\n    width: 1em;\n    height: 1em;\n    vertical-align: middle;\n    fill: currentColor;\n    margin-top: -0.25em;\n    /* 4px */\n    margin-right: 0.25em;\n    /* 4px */\n}\n/* style 1 */\n/* .inputfile-1 + label {\n    color: #f1e5e6;\n    background-color: #d3394c;\n} */\n.inputfile-1:focus + label,\n.inputfile-1.has-focus + label,\n.inputfile-1 + label:hover {\n    color: #555;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
