// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.multiselect-dropdown .dropdown-list {
    max-height: 200px;
    overflow: scroll;
}
.s_action {
	min-width: 85px;
}
.s_action select {
	font-size: 14px;
}
.back_rv {
	background-color: rgb(0 0 0 / 0%) !important;
}
span.datetime.d-block {
    width: 150px;
}
`, "",{"version":3,"sources":["webpack://./src/app/dd-terminal/allotmentlist/allotmentlist.component.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,gBAAgB;AACpB;AACA;CACC,eAAe;AAChB;AACA;CACC,eAAe;AAChB;AACA;CACC,4CAA4C;AAC7C;AACA;IACI,YAAY;AAChB","sourcesContent":[".multiselect-dropdown .dropdown-list {\n    max-height: 200px;\n    overflow: scroll;\n}\n.s_action {\n\tmin-width: 85px;\n}\n.s_action select {\n\tfont-size: 14px;\n}\n.back_rv {\n\tbackground-color: rgb(0 0 0 / 0%) !important;\n}\nspan.datetime.d-block {\n    width: 150px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
