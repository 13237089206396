import { Component, OnInit } from '@angular/core';
import { DatapassService } from '../../services/datapass.service';
import Stepper from 'bs-stepper';
import { BackenddbService } from '../../services/backenddb.service';
import Swal from 'sweetalert2';
import { LoginService } from '../../services/login.service';
import { faStar,faCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-viewexeroadmap',
  templateUrl: './viewexeroadmap.component.html',
  styleUrls: ['./viewexeroadmap.component.css']
})
export class ViewexeroadmapComponent implements OnInit {
  private stepper: Stepper;
  allotRoadmap = [];
  exercise:any = {};
  roadmapStatusList = ['Pending','Completed'];
  currentRoadmapStatus = [];
  server_url:string;
  callFrom:string = 'allotment';

  faStar = faStar
  faCircle = faCircle
  constructor(
    private ds: DatapassService,
    private BackenddbService: BackenddbService,
    private LoginService: LoginService,   
  ) { 
    this.server_url = this.LoginService.getServerUrl();
  }

  ngOnInit(): void {
  }

  next() {
    this.stepper.next();
  }
  stepperProgressBar()
  {
    this.stepper = new Stepper(document.querySelector('#stepper1'), {
      linear: false,
      animation: true
    })
  }
  changeRoadmapStatus(event:any,index:any)
  {
    this.currentRoadmapStatus[index] = event.target.value;
  }
  updateRoadmapStatus(id:any,pkag_gp_id:any,index:any)
  {
    this.ds.Loader(true);
		const formData = new FormData();
		formData.append('id',id);
    formData.append('pkag_gp_id',pkag_gp_id);
		formData.append('status',this.currentRoadmapStatus[index]);
    var api = 'admin-api/update-allot-roadmap-status';
		this.BackenddbService.postData(api,formData).subscribe((res:any) => {
      this.ds.Loader(false);
			if(res.status=='success')
			{
        (<any>$('#steeperProgress')).modal('hide');
				Swal.fire('',res.message,'success');
       // this.rerender_datatable('allotment-table');
			}else if(res.status=='error')
			{
				Swal.fire('',res.message,'warning');
			}
			});
  }
  
  getRoadMap(pkgeId:any='',altId:any='',callFrom:any='')
  {
    this.ds.Loader(true)
    this.allotRoadmap = []
    this.callFrom = callFrom
  	const formData = new FormData();
    let api = 'admin-api/get-allot-roadmap-details';
    if(callFrom=="allotment")
    {
      formData.append('pkag_gp_id',pkgeId);
      formData.append('id',altId);
    }else
    {
      api = 'admin-api/package-roadmap';
      formData.append('pkag_id',pkgeId);
    }
		this.BackenddbService.postData(api,formData).subscribe((res:any) => {
      this.ds.Loader(false);
      if(res.status=='success')
      {
        this.allotRoadmap = res.data.allotRoadmap;
        this.currentRoadmapStatus = [];
        this.allotRoadmap.forEach((dobj:any, dataindex:any) => { 
          this.currentRoadmapStatus.push(dobj.status)
        });     
        (<any>$('#steeperProgress')).modal('show');
        setTimeout(() => {
          this.stepperProgressBar()
          this.stepper.to(res.data.currentOrder)
        },10);
      }
    });  
  }

  modalClose(id)
  {
    (<any>$(id)).modal('hide');
  }
  showExeDetail(index:any)
  {
    this.exercise = this.allotRoadmap[index];
    (<any>$('#exeDetail')).modal('show');
  }

}
