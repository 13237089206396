// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.basketbox {
    box-shadow: 0 2px 9px 2px rgb(0 0 0 / 14%);
}
.table th {
    font-size: 16px !important;
	font-family: 'Ubuntu-Medium', sans-serif;
	padding: 0.75rem 0.60rem;
}
.table td {
    font-size: 14px !important;
    font-family: 'Open Sans', sans-serif;
	padding: 8px 10px !important;
	vertical-align: middle;
}
.table .thead-dark th {
    color: #fff;
    background-color: #141d38;
    border-color: #141d38;
}
@media only screen and (min-width: 1600px)
{
	.dk-table table.dataTable .tluc, .dk-table .tluc {
		min-width: 185px !important;
	}
}`, "",{"version":3,"sources":["webpack://./src/app/dd-terminal/viewpurchasevoucherlist/viewpurchasevoucherlist.component.css"],"names":[],"mappings":"AAAA;IACI,0CAA0C;AAC9C;AACA;IACI,0BAA0B;CAC7B,wCAAwC;CACxC,wBAAwB;AACzB;AACA;IACI,0BAA0B;IAC1B,oCAAoC;CACvC,4BAA4B;CAC5B,sBAAsB;AACvB;AACA;IACI,WAAW;IACX,yBAAyB;IACzB,qBAAqB;AACzB;AACA;;CAEC;EACC,2BAA2B;CAC5B;AACD","sourcesContent":[".basketbox {\n    box-shadow: 0 2px 9px 2px rgb(0 0 0 / 14%);\n}\n.table th {\n    font-size: 16px !important;\n\tfont-family: 'Ubuntu-Medium', sans-serif;\n\tpadding: 0.75rem 0.60rem;\n}\n.table td {\n    font-size: 14px !important;\n    font-family: 'Open Sans', sans-serif;\n\tpadding: 8px 10px !important;\n\tvertical-align: middle;\n}\n.table .thead-dark th {\n    color: #fff;\n    background-color: #141d38;\n    border-color: #141d38;\n}\n@media only screen and (min-width: 1600px)\n{\n\t.dk-table table.dataTable .tluc, .dk-table .tluc {\n\t\tmin-width: 185px !important;\n\t}\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
