// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loginform p{
    font-family: 'OpenSans-SemiBold', sans-serif;
}`, "",{"version":3,"sources":["webpack://./src/app/registration/thankyou/thankyou.component.css"],"names":[],"mappings":"AAAA;IACI,4CAA4C;AAChD","sourcesContent":[".loginform p{\n    font-family: 'OpenSans-SemiBold', sans-serif;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
