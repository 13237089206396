import { Component, OnInit,Output, EventEmitter } from '@angular/core';
import Swal from 'sweetalert2';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

import { BackenddbService } from '../../services/backenddb.service';
import { DatapassService } from '../../services/datapass.service';

import { Constants } from 'src/constants';

@Component({
  selector: 'app-editchallenge',
  templateUrl: './editchallenge.component.html',
  styleUrls: ['./editchallenge.component.css']
})
export class EditchallengeComponent implements OnInit {
  @Output() reloadParentData = new EventEmitter<any>();

  faCheck = faCheck
  faTimes = faTimes

  answer:any = []

  currentChallId:any = ''
  challengeName:any = ''
  attendId:any = ''
  task:any = []
  title:string = ''
  options: any = []
  

  constructor(
    private BackenddbService: BackenddbService,
    private ds:DatapassService,
  ) { }

  ngOnInit(): void {
  }


  onSubmit() {
    Swal.fire({
      title: 'Are you sure?',
      text: Constants.ALERT_SUBMIT,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'OK',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.value) {
        (<any>$('#challEditModal')).modal('hide');
        this.ds.Loader(true);
        const formData = new FormData();
        formData.append('answer', JSON.stringify(this.answer));
        formData.append('attendId', this.attendId);
        this.BackenddbService.postData('challenge-api/update-attendees-challenge', formData).subscribe((res: any) => {
          this.ds.Loader(false);
          if (res.status == 'success') {
            this.reloadParentData.next(true)
          }
        })
      }
    });
  }

  selectAnswer(event:any, t:any, i:any, k:any,ans:any)
  {
        this.task[t]['question'][i]['answer'].forEach((ansObj:any,ansIndx:any)=>{
          let correct = 0
          if(k==ansIndx)
          {
            correct = ans
          }
          this.task[t]['question'][i]['answer'][ansIndx].correct = correct
        })

         let quesId = this.task[t]['question'][i]['id']
          let indx = this.answer.findIndex((obj:any,indx:any)=>{
            return obj.attend_ques_id == quesId
         })

         let temp = {
            attend_ques_id:quesId,
            answer:this.task[t]['question'][i]['answer']          
          }
         if(indx>=0)
         {
           this.answer[indx] = temp
         }else
         {
          this.answer.push(temp)
         }        
  }

  modalClose()
  {
    (<any>$('#challEditModal')).modal('hide');
  }
  
  openEditChallModal(id:any)
  {
      this.ds.Loader(true)
      const formData = new FormData()
      formData.append('id',id)
      this.attendId = ''
      this.BackenddbService.postData('challenge-api/get-attendee-challenge',formData).subscribe((res:any) => {
        this.ds.Loader(false)
        if(res.status=='success')
        {
          this.title = res.challengeName+" (Score - "+res.totalObtain+"/"+res.totalScore+")";
          this.task = res.task;
          this.task.forEach((obj:any,index:any)=>{
            obj.question.forEach((quesObj:any,quesIndx:any)=>{
              quesObj.answer.forEach((ansObj:any,ansIndx:any)=>{
                this.task[index].question[quesIndx].answer[ansIndx].correct = parseInt(ansObj.correct)
              })
            })
          })
          this.attendId = id;
          (<any>$('#challEditModal')).modal('show');
        }else
        {
          Swal.fire('Oops...', res.message, 'error');
        }
      });
  }

}
