import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule,ReactiveFormsModule  } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataTablesModule } from 'angular-datatables';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import {DpDatePickerModule} from 'ng2-date-picker';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { NgbdModalBasic } from './../modal-basic';

import { NotificationsComponent } from '../dd-terminal/notifications/notifications.component';
import { BreadcrumbsComponent } from '../breadcrumbs/breadcrumbs.component';
import { ViewnetworkComponent } from '../dd-terminal/viewnetwork/viewnetwork.component';
import { ViewpurchasevoucherlistComponent } from '../dd-terminal/viewpurchasevoucherlist/viewpurchasevoucherlist.component';
import { ViewuserprofileComponent } from '../dd-terminal/viewuserprofile/viewuserprofile.component';
import { ViewwallettransitionlistComponent } from '../dd-terminal/viewwallettransitionlist/viewwallettransitionlist.component';
import { ScheduleComponent } from '../schedule/schedule.component';
import { LeaderboardtableComponent } from '../leaderboardtable/leaderboardtable.component';

import { PhotocropComponent } from '../dd-terminal/photocrop/photocrop.component';
import { EditchallengeComponent } from '../dd-terminal/editchallenge/editchallenge.component';
import { MyscoreboardlistComponent } from '../myscoreboardlist/myscoreboardlist.component';


import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { ImageCropperModule } from 'ngx-image-cropper';

@NgModule({
  declarations: [
    NgbdModalBasic,
    NotificationsComponent,
    BreadcrumbsComponent,
    ViewnetworkComponent,
    ViewpurchasevoucherlistComponent,
    ViewuserprofileComponent,
    ViewwallettransitionlistComponent,
    ScheduleComponent,
    LeaderboardtableComponent,
    PhotocropComponent,
    EditchallengeComponent,
    MyscoreboardlistComponent
  ],
  exports: [
    NotificationsComponent,
    BreadcrumbsComponent,
    ViewnetworkComponent,
    LeaderboardtableComponent,
    ViewpurchasevoucherlistComponent,
    ViewuserprofileComponent,
    ViewwallettransitionlistComponent,
    ScheduleComponent,
    PhotocropComponent,
    MyscoreboardlistComponent,
    FontAwesomeModule,
    DragDropModule,
    FormsModule,
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    RouterModule,
    HttpClientModule,
    DpDatePickerModule,
    AutocompleteLibModule,

  ],
  imports: [
    CommonModule,
    DataTablesModule,
    FontAwesomeModule,
    DragDropModule,
    RouterModule,
    FormsModule,
    BrowserModule,
    NgbModule,
    NgbNavModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    DpDatePickerModule,
    AutocompleteLibModule,
    ImageCropperModule
  ]
})
export class SharedModule { }
