import { Component, OnInit, Output, Input, EventEmitter,ElementRef,ViewChild } from '@angular/core';
import { ImageCroppedEvent,ImageTransform,Dimensions } from 'ngx-image-cropper';
import { faSave, faEdit, faBars,faPlus,faMinus} from '@fortawesome/free-solid-svg-icons';
import { UntypedFormGroup,  UntypedFormBuilder, Validators  } from '@angular/forms';
import { imgValidator } from '../../helper/validatefun';
declare const setInputFile: any;

@Component({
  selector: 'app-photocrop',
  templateUrl: './photocrop.component.html',
  styleUrls: ['./photocrop.component.css']
})

export class PhotocropComponent implements OnInit {
  @Output() uploadImage = new EventEmitter<any>();
  @Input() title:string = 'Crop Image';
  @Input() roundCropper:boolean = false;
  @Input() imgWidth:number = 79;
  @Input() imgHeight:number = 79;
  @Input() aspectRatio:any = '1/1';
  @Input() btnText:any = 'Upload';
  @ViewChild('inputFile') fileInputRef: ElementRef;

  fileInputLabel:any = 'Choose a image...';
  faSave = faSave;
  faEdit = faEdit;
  faBars = faBars;
  faPlus = faPlus;
  faMinus = faMinus;
  imageChangedEvent:any = '';
  croppedImage:any = '';
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};
  importFrm:UntypedFormGroup;
  constructor(
    private fb:UntypedFormBuilder
  ) { 
  }
  ngOnInit(): void {
    this.importFrm = this.fb.group({
      exeIcon: ['', [Validators.required,imgValidator]],
    });
  }
  onSubmit()
  {
    this.uploadImage.emit(this.croppedImage);
  }
  changeIconPopUp()
  {
    this.croppedImage = '';
    this.imageChangedEvent = '';
    this.fileInputRef.nativeElement.value = '';
    let label = document.querySelector('.inputfile').nextElementSibling;
    label.querySelector( 'span' ).innerHTML = this.fileInputLabel;
    (<any>$('#icon-change')).modal('show');
    //setInputFile();
  }
  closePopUp()
  {
    (<any>$('#icon-change')).modal('hide');
  }
  fileChangeEvent(event: any) 
  {
      var val = event.target.value;
      // Allowing file type
      var allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
      if(!allowedExtensions.exec(val)) 
      {
        return false;
      }

		  var label = event.target.nextElementSibling;
      let fileName = val.split( '\\' ).pop();
			if(fileName)
      {
				 label.querySelector( 'span' ).innerHTML = fileName;
      }
			else
      {
         label.innerHTML = this.fileInputLabel;
      }
      this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
      this.croppedImage = event.base64;
  }
  imageLoaded() {
      this.showCropper = true;
  }
  cropperReady(sourceImageDimensions: Dimensions) {
  }
  loadImageFailed() {
  } 
  zoomOut() {
      this.scale -= .1;
      this.transform = {
          ...this.transform,
          scale: this.scale
      };
  }
  zoomIn() {
      this.scale += .1;
      this.transform = {
          ...this.transform,
          scale: this.scale
      };
  }
  toggleContainWithinAspectRatio() {
      this.containWithinAspectRatio = !this.containWithinAspectRatio;
  }
}