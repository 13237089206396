// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cr-pluse {
    color: #008e00;
    font-weight: 600 !important;
}
.cr-minus {
    color: #ff3e3e;
    font-weight: 600 !important;
}

.cr-pluse .crpi{
    font-size: 10px;
    min-width: 13px;
    display: inline-block;
}
.cr-minus .crmi{
    font-size: 9px;
    min-width: 14px;
    display: inline-block;
}
.bshadow {
    box-shadow: 0 2px 9px 2px rgb(0 0 0 / 14%);
}
.dahboard_blocksd .block-content.block-content-sm.block-content-full {
    padding-bottom: 0;
}
.w-tra{
    display: flow-root;
}
.width-350{
    width:300px !important;
}
.wthead th {
    padding: 10px 15px;
    border-top: 0 !important;
    border-bottom: 1px solid #dee2e6 !important;
}
.wallet td {
    padding: 20px 15px;
}
.w-icon {
    float: left;
    width: 30px;
    height: 30px;
    text-align: center;
    background: #cecece;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 100pc;
    margin-right: 9px;
    margin-top: 5px;
}
.w-tra {
    font-family: 'OpenSans-SemiBold', sans-serif;
}
.w-tra span{
    display: block;
    font-size: 13px;
    text-transform: uppercase;
    color: #808080;
}`, "",{"version":3,"sources":["webpack://./src/app/dd-terminal/viewwallettransitionlist/viewwallettransitionlist.component.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,2BAA2B;AAC/B;AACA;IACI,cAAc;IACd,2BAA2B;AAC/B;;AAEA;IACI,eAAe;IACf,eAAe;IACf,qBAAqB;AACzB;AACA;IACI,cAAc;IACd,eAAe;IACf,qBAAqB;AACzB;AACA;IACI,0CAA0C;AAC9C;AACA;IACI,iBAAiB;AACrB;AACA;IACI,kBAAkB;AACtB;AACA;IACI,sBAAsB;AAC1B;AACA;IACI,kBAAkB;IAClB,wBAAwB;IACxB,2CAA2C;AAC/C;AACA;IACI,kBAAkB;AACtB;AACA;IACI,WAAW;IACX,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,mBAAmB;IACnB,mBAAmB;IACnB,aAAa;IACb,uBAAuB;IACvB,oBAAoB;IACpB,iBAAiB;IACjB,eAAe;AACnB;AACA;IACI,4CAA4C;AAChD;AACA;IACI,cAAc;IACd,eAAe;IACf,yBAAyB;IACzB,cAAc;AAClB","sourcesContent":[".cr-pluse {\n    color: #008e00;\n    font-weight: 600 !important;\n}\n.cr-minus {\n    color: #ff3e3e;\n    font-weight: 600 !important;\n}\n\n.cr-pluse .crpi{\n    font-size: 10px;\n    min-width: 13px;\n    display: inline-block;\n}\n.cr-minus .crmi{\n    font-size: 9px;\n    min-width: 14px;\n    display: inline-block;\n}\n.bshadow {\n    box-shadow: 0 2px 9px 2px rgb(0 0 0 / 14%);\n}\n.dahboard_blocksd .block-content.block-content-sm.block-content-full {\n    padding-bottom: 0;\n}\n.w-tra{\n    display: flow-root;\n}\n.width-350{\n    width:300px !important;\n}\n.wthead th {\n    padding: 10px 15px;\n    border-top: 0 !important;\n    border-bottom: 1px solid #dee2e6 !important;\n}\n.wallet td {\n    padding: 20px 15px;\n}\n.w-icon {\n    float: left;\n    width: 30px;\n    height: 30px;\n    text-align: center;\n    background: #cecece;\n    align-items: center;\n    display: flex;\n    justify-content: center;\n    border-radius: 100pc;\n    margin-right: 9px;\n    margin-top: 5px;\n}\n.w-tra {\n    font-family: 'OpenSans-SemiBold', sans-serif;\n}\n.w-tra span{\n    display: block;\n    font-size: 13px;\n    text-transform: uppercase;\n    color: #808080;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
