// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.crop-image {
    border-radius: 100%;
    border: 2px solid #d8d8d8;
}
@media only screen and (min-width: 1025px) 
{
	.mtain {
		margin-top: 0px;
	}
}
`, "",{"version":3,"sources":["webpack://./src/app/dd-terminal/teamlist/teamlist.component.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,yBAAyB;AAC7B;AACA;;CAEC;EACC,eAAe;CAChB;AACD","sourcesContent":[".crop-image {\n    border-radius: 100%;\n    border: 2px solid #d8d8d8;\n}\n@media only screen and (min-width: 1025px) \n{\n\t.mtain {\n\t\tmargin-top: 0px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
