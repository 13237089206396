// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ng-autocomplete {
    width: auto;
}
.resourcedd {
	padding-left: 0;
	max-width: 472px;
}
.resourcedd div.autocomplete-container {
	box-shadow: none !important;
	border-radius: .25rem;
	border: 1px solid #ccc;
}
@media only screen and (min-width: 768px) 
{	
	.myform .plg0  {
		padding: 0;
	}
	.tbp {
		padding: 5px 0 0 5px;
	}
	.content-box-shadow {
		max-width: 1000px;
	}

}
@media only screen and (max-width: 767px) 
{	
	.myform .plg0  {
		padding: 0;
	}
}
@media only screen and (max-width: 1024px) 
{
	.resourcedd {
		padding-left: 15px;
	}	
}
@media only screen and (min-width : 1025px)
{
	
	.myform .col_lable {
		flex: 0 0 250px;
		max-width: 250px;
	}
	.myform .col_input {
		flex: 0 0 130px;
		max-width: 130px;
		padding: 0;
	}
}
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) 
{
	/*.myform .col_lable {
		padding-left: 0px;
	}*/
}
@media only screen and (min-width: 1025px) 
{
	.myform .onecell {
		flex: 0 0 380px;
		max-width: 380px;
	}
}
`, "",{"version":3,"sources":["webpack://./src/app/dd-terminal/resource/resource.component.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;AACA;CACC,eAAe;CACf,gBAAgB;AACjB;AACA;CACC,2BAA2B;CAC3B,qBAAqB;CACrB,sBAAsB;AACvB;AACA;;CAEC;EACC,UAAU;CACX;CACA;EACC,oBAAoB;CACrB;CACA;EACC,iBAAiB;CAClB;;AAED;AACA;;CAEC;EACC,UAAU;CACX;AACD;AACA;;CAEC;EACC,kBAAkB;CACnB;AACD;AACA;;;CAGC;EACC,eAAe;EACf,gBAAgB;CACjB;CACA;EACC,eAAe;EACf,gBAAgB;EAChB,UAAU;CACX;AACD;AACA;;CAEC;;GAEE;AACH;AACA;;CAEC;EACC,eAAe;EACf,gBAAgB;CACjB;AACD","sourcesContent":[".ng-autocomplete {\n    width: auto;\n}\n.resourcedd {\n\tpadding-left: 0;\n\tmax-width: 472px;\n}\n.resourcedd div.autocomplete-container {\n\tbox-shadow: none !important;\n\tborder-radius: .25rem;\n\tborder: 1px solid #ccc;\n}\n@media only screen and (min-width: 768px) \n{\t\n\t.myform .plg0  {\n\t\tpadding: 0;\n\t}\n\t.tbp {\n\t\tpadding: 5px 0 0 5px;\n\t}\n\t.content-box-shadow {\n\t\tmax-width: 1000px;\n\t}\n\n}\n@media only screen and (max-width: 767px) \n{\t\n\t.myform .plg0  {\n\t\tpadding: 0;\n\t}\n}\n@media only screen and (max-width: 1024px) \n{\n\t.resourcedd {\n\t\tpadding-left: 15px;\n\t}\t\n}\n@media only screen and (min-width : 1025px)\n{\n\t\n\t.myform .col_lable {\n\t\tflex: 0 0 250px;\n\t\tmax-width: 250px;\n\t}\n\t.myform .col_input {\n\t\tflex: 0 0 130px;\n\t\tmax-width: 130px;\n\t\tpadding: 0;\n\t}\n}\n@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) \n{\n\t/*.myform .col_lable {\n\t\tpadding-left: 0px;\n\t}*/\n}\n@media only screen and (min-width: 1025px) \n{\n\t.myform .onecell {\n\t\tflex: 0 0 380px;\n\t\tmax-width: 380px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
