import { Component, OnInit,ViewChild,Input,ViewEncapsulation,QueryList,ViewChildren } from '@angular/core';
import { HttpClient,HttpParams  } from '@angular/common/http';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';

import { DataTableDirective } from 'angular-datatables';
import Swal from 'sweetalert2';
import { faUser, faUsers ,faEdit,faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

import { faCrown } from '@fortawesome/free-solid-svg-icons'; 
import { LoginService } from '../services/login.service';
import { BackenddbService } from '../services/backenddb.service';
import { DatapassService } from '../services/datapass.service';
import { EditchallengeComponent } from '../dd-terminal/editchallenge/editchallenge.component';
import { MyscoreboardlistComponent } from '../myscoreboardlist/myscoreboardlist.component';

import { numberToRankText } from '../helper/custom';

class leaderboard {
  id: number;
  firstName: string;
  lastName: string;
}

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
  param:any;
}
@Component({
  selector: 'app-leaderboardtable',
  templateUrl: './leaderboardtable.component.html',
  styleUrls: ['./leaderboardtable.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class LeaderboardtableComponent implements OnInit {
  @Input() side: string = 'front';
  @ViewChild(EditchallengeComponent) editChallComp;
  @ViewChild(MyscoreboardlistComponent) myScoreListComp;

  faEdit = faEdit
  closeResult: string;
  faCrown = faCrown
  faUser = faUser
  faUsers = faUsers
  faCheck = faCheck
  faTimes = faTimes

  @ViewChildren(DataTableDirective)
  dtElements: QueryList<DataTableDirective>;
  dtOptions: DataTables.Settings[] = [];
  //dtTrigger = new Subject();
  dtMyScoreTrigger = new Subject();
  searchVal:string = ''
  numberToRankText:any

  groupId:string = '';
  closed: boolean = true;
  userId:string = ''
  leaderboard: leaderboard[]
  challenges:any = []

  serverUrl:string = environment.baseUrl;
  status:string = ''
  totalScore:number = 0
  totalObtain:number = 0
	active = 1;
  initMyScore:boolean = false
  title:string = ''
  currentChallId:string = ''
  challengeName:string = ''

  constructor(
    private http: HttpClient,
    private loginService: LoginService,
    private BackenddbService: BackenddbService,
    private ds: DatapassService
    ) 
    {
      this.userId = this.loginService.getUserId(); 
      this.groupId = this.loginService.getLoginGroup();
      this.numberToRankText = numberToRankText 
      this.createLeaderBoard()
    }
   ngOnInit(): void {
  }
  //This function call when attendees answer updated it will call by editChallengeComponent
  reloadData()
  {
      this.showChallenge(this.currentChallId, this.challengeName)
      this.renderDataTable('leaderboard-table');
  }
  //Here call editChallengeComponent function for show attendees challenge answer for update
  openEditChallModal(id:any)
  {
     this.editChallComp.openEditChallModal(id)
  }
  createLeaderBoard()
  {
       /* user data table */
       const that = this;
       this.dtOptions[0] = {
         dom: '<"top">tr<"bottom"ilp><"clear">',
         pagingType: 'full_numbers',
         pageLength: 10,
         serverSide: true,
         processing: true,
         order: [[ 0, "asc" ]],
         ajax: (dataTablesParameters: any, callback:any ) => {
           let params = new HttpParams();
           let startNumber: any;
           startNumber = dataTablesParameters.start;
           if (startNumber != 0) {
             startNumber = startNumber + 1
           } else {
             startNumber = startNumber;
           }
           params = params.append("start", startNumber);
           params = params.append("length", dataTablesParameters.length);
           params = params.append("draw", dataTablesParameters.draw);
           params = params.append("search", this.searchVal);
           params = params.append("order_col", dataTablesParameters.order[0].column);
           params = params.append("order_type", dataTablesParameters.order[0].dir);        
           params = params.append("status", this.status);
           let param = params.toString();
           that.http
             .post<DataTablesResponse>(
               this.serverUrl+'datatable-api/get-leaderboard-list',
               params, {}
             ).subscribe(resp => {
               that.leaderboard = resp.data;
               callback({
                 recordsTotal: resp.recordsTotal,
                 recordsFiltered: resp.recordsFiltered,
                 data: []
               });
             });
         },
         columns: [{ data: 's_no' }, { data: 'name' }, { data: 'score' }, { data: 'last_datetime' }]
       };
  }
  datatableSearch(event:any,tableId:any){
    this.searchVal = event.target.value; 
    this.renderDataTable(tableId);
  }
  ngAfterViewInit(): void {
  // this.dtTrigger.next(true);
  }
  ngOnDestroy(): void {
    //this.dtTrigger.unsubscribe();
  }
  renderDataTable(datatableName:any) {
    this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
      dtElement.dtInstance.then((dtInstance: any) => {
        if(dtInstance.table().node().id == datatableName) {
           dtInstance.draw();
        }
      });
    });
  }
  showChallenge(id:any,challengeName:any)
  {
    this.currentChallId = id
    this.challengeName = challengeName
    this.ds.Loader(true)
    this.challenges = []
    this.totalScore = 0
    this.totalObtain = 0
    const formData = new FormData()
    formData.append('challengeId',id)
    this.BackenddbService.postData('challenge-api/get-all-challenge',formData).subscribe((res:any) => {
      this.ds.Loader(false)
      if(res.status=='success')
      {
        let data = res.data;
        this.challenges = data.challenges;
        this.totalScore = data.totalScore;
        this.totalObtain = data.totalObtain;
        (<any>$('#challModal')).modal('show');
      }else
      {
        Swal.fire('Oops...', res.message, 'error');
      }
    });
  }
   myScoreboard()
  {
    if(this.initMyScore==false)
    {
      this.initMyScore = true
      this.myScoreListComp.myScoreboard()
    }
  }
}

