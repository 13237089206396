import { Injectable } from '@angular/core';
import { Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpClient, HttpErrorResponse,HttpHeaders  } from '@angular/common/http';

import { Router } from '@angular/router';
import { LoginService } from './services/login.service';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {

  return_val:boolean;
  roleId:number;
  role:any = [];
  serverUrl = environment.baseUrl;
  routeRole:any = [
    { id:[1],role:['ADMIN']},
    { id:[3],role:['USER']},
    { id:[2,4],role:['INSTRUCTOR','CORPORATE']},

  ]

  constructor(private loginservice: LoginService,
              private router: Router,
              private http: HttpClient) {
   }

 async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
    return await this.checkUserLogin(next, state.url);
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }

  async checkUserLogin(route: ActivatedRouteSnapshot, url: any): Promise<boolean> 
  {
      const formData = new FormData();
      var session_id = this.loginservice.getLoginSessionId();
      this.role = route.data.role;
      formData.append('session_id',session_id);
        var res:any = await new Promise((resolve, reject) => {
          this.http.post<any>(this.serverUrl + 'login-api/verify-session', formData)
              .subscribe({
                  next: (response) => {
                      resolve(response);
                  },
                  error: (error) => {
                      reject(error);
                  }
              });
      });

      if(res.return==true)
      {
          this.loginservice.setLoginSession(res.data);
          if(this.role.includes('CHECK'))
          {
             return true;
          }
          if(this.role.includes('CHECK_LOGIN'))
          {
             return true;
          }
          this.roleId = parseInt(res.data.group_id);
      }else
      {
        this.loginservice.logOut();
        if(this.role.includes('CHECK'))
        {
          return true;
        }else if(this.role.includes('CHECK_LOGIN'))
        {
          this.router.navigate(['/login']);
          return false;
        }else
        {
          this.router.navigate(['/login']);
          return false;
        }
      }
      const accessData = this.routeRole.find((obj)=>obj.id.includes(this.roleId))
      if(typeof accessData!="undefined" && this.role.every(obj => accessData?.role.includes(obj)))
      {
        return true;
      }
      this.router.navigate(['/login']);
      return false;
    }

}
