// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (min-width: 1024px)
{
    .myform .col_lable {
        max-width: 165px !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/app/dd-terminal/team/team.component.css"],"names":[],"mappings":"AAAA;;IAEI;QACI,2BAA2B;IAC/B;AACJ","sourcesContent":["@media only screen and (min-width: 1024px)\n{\n    .myform .col_lable {\n        max-width: 165px !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
