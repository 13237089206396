// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.olpl {
    padding-left: 20px;
}
.ttflex {
    display: flex;
    border-bottom: 1px solid rgba(0,0,0,.1);
}
.paddy {
    padding-top: .5rem;
    padding-bottom: .5rem;
}
.ttsn {
    display: inline-block;
    min-width: 21px;
    text-align: center;
}
.mr-2 {
    margin-right: .5rem;
}
.ttn {
    flex: 1 1 0%;
}
.mr-4 {
    margin-right: 1rem;
}
.ttn a {
    color: #000; 
}
.ttn a:hover {
    color: #0060ff;
}
.content-box-shadow-sec {
    margin-bottom: 17px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/dd-terminal/manual/manual.component.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;AACA;IACI,aAAa;IACb,uCAAuC;AAC3C;AACA;IACI,kBAAkB;IAClB,qBAAqB;AACzB;AACA;IACI,qBAAqB;IACrB,eAAe;IACf,kBAAkB;AACtB;AACA;IACI,mBAAmB;AACvB;AACA;IACI,YAAY;AAChB;AACA;IACI,kBAAkB;AACtB;AACA;IACI,WAAW;AACf;AACA;IACI,cAAc;AAClB;AACA;IACI,8BAA8B;AAClC","sourcesContent":[".olpl {\n    padding-left: 20px;\n}\n.ttflex {\n    display: flex;\n    border-bottom: 1px solid rgba(0,0,0,.1);\n}\n.paddy {\n    padding-top: .5rem;\n    padding-bottom: .5rem;\n}\n.ttsn {\n    display: inline-block;\n    min-width: 21px;\n    text-align: center;\n}\n.mr-2 {\n    margin-right: .5rem;\n}\n.ttn {\n    flex: 1 1 0%;\n}\n.mr-4 {\n    margin-right: 1rem;\n}\n.ttn a {\n    color: #000; \n}\n.ttn a:hover {\n    color: #0060ff;\n}\n.content-box-shadow-sec {\n    margin-bottom: 17px !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
