import { Component, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { LoginService } from '../../services/login.service';
import { MyscoreboardlistComponent } from '../../myscoreboardlist/myscoreboardlist.component';
declare const activesidebar:any;
@Component({
  selector: 'app-attendeeschallengelist',
  templateUrl: './attendeeschallengelist.component.html',
  styleUrls: ['./attendeeschallengelist.component.css']
})
export class AttendeeschallengelistComponent implements OnInit {
  @ViewChild(MyscoreboardlistComponent) myScoreListComp;
  viewProfile : Subject<any> = new Subject<any>();
  public _opened: boolean = true; 
	public _toggleSidebar() {
		this._opened = !this._opened;
	} 


  serverUrl = environment.baseUrl;

  /*flash message*/
  msgalert:any;
  
  /*breadcrumbs array */
  current_url_array = [
    {'slug':"",'label':'Allot Challenges List'}
    ];;
  status_array = [{label:'Pending',val:'Pending'},{label:'Completed',val:'Completed'}];
  group_id:string = '';
  update_by:string;
  dashboard_url:string; 

  form_title:any = 'Allot Challenges List';
  searchVal = '';
 
  constructor(
    private LoginService: LoginService,   
    ) {
        this.update_by = this.LoginService.getUserId();
        this.group_id = this.LoginService.getLoginGroup();
        this.dashboard_url = this.LoginService.getDashboardUrl();
    }
		ngOnInit() {
      activesidebar(); 
	  }
  }
