// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-google {
    background-color: #dd4b39;
    color: white;
}
.block-center {
    margin: auto;
}
.social-media .so_image{
    width: auto;
    height: auto;
    max-width: 80px;
}
.lw {
    color: #fff;
    font-family: 'OpenSans-SemiBold', sans-serif;
}
.social {
    font-size: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
}
.social img{
    margin-right: 5px;
}
.shr {
    margin-top: 0;
    border-top: 1px solid #bdbdbd;
}
.or-container {
    align-items: center;
    color: #ccc;
    display: flex;
    margin: 25px 0
}

.line-separator {
    background-color: #ccc;
    flex-grow: 5;
    height: 1px
}
.btn-google {
    color: rgb(68, 65, 65);
    background-color: #fff;
}
.btn-linkedin {
    color: #fff;
    background-color: #0e76a8;
}
.or-label {
    flex-grow: 1;
    margin: 0 15px;
    text-align: center
}`, "",{"version":3,"sources":["webpack://./src/app/login/login.component.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,YAAY;AAChB;AACA;IACI,YAAY;AAChB;AACA;IACI,WAAW;IACX,YAAY;IACZ,eAAe;AACnB;AACA;IACI,WAAW;IACX,4CAA4C;AAChD;AACA;IACI,eAAe;IACf,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,uBAAuB;AAC3B;AACA;IACI,iBAAiB;AACrB;AACA;IACI,aAAa;IACb,6BAA6B;AACjC;AACA;IACI,mBAAmB;IACnB,WAAW;IACX,aAAa;IACb;AACJ;;AAEA;IACI,sBAAsB;IACtB,YAAY;IACZ;AACJ;AACA;IACI,sBAAsB;IACtB,sBAAsB;AAC1B;AACA;IACI,WAAW;IACX,yBAAyB;AAC7B;AACA;IACI,YAAY;IACZ,cAAc;IACd;AACJ","sourcesContent":[".btn-google {\n    background-color: #dd4b39;\n    color: white;\n}\n.block-center {\n    margin: auto;\n}\n.social-media .so_image{\n    width: auto;\n    height: auto;\n    max-width: 80px;\n}\n.lw {\n    color: #fff;\n    font-family: 'OpenSans-SemiBold', sans-serif;\n}\n.social {\n    font-size: 14px;\n    display: flex;\n    align-items: center;\n    text-align: center;\n    justify-content: center;\n}\n.social img{\n    margin-right: 5px;\n}\n.shr {\n    margin-top: 0;\n    border-top: 1px solid #bdbdbd;\n}\n.or-container {\n    align-items: center;\n    color: #ccc;\n    display: flex;\n    margin: 25px 0\n}\n\n.line-separator {\n    background-color: #ccc;\n    flex-grow: 5;\n    height: 1px\n}\n.btn-google {\n    color: rgb(68, 65, 65);\n    background-color: #fff;\n}\n.btn-linkedin {\n    color: #fff;\n    background-color: #0e76a8;\n}\n.or-label {\n    flex-grow: 1;\n    margin: 0 15px;\n    text-align: center\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
