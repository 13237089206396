// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fronttbl table {
    border: 0px solid #dee2e6;
}
.fronttbl .table td {
	font-size: 16px !important;
    font-family: 'Open Sans', sans-serif;
    vertical-align: middle;
	padding: 25px 10px 0px !important;
}
.fronttbl .table .thead-dark th {
    color: #141d38;
    background-color: #fff;
    border-color: #dee2e6;
}
.fronttbl .table td{
    border-top: 0px solid #dee2e6;
}
.fronttbl .table thead th {
    border-top: 2px solid #dee2e6;
}
.fronttbl .max150 {
	max-width: 150px;
}
.fronttbl .max33{
	width: 33.33%;
}
.defstatus{
	/*background: #141d38;
    color: #fff;*/
	background: #eaeaea;
    color: #000;
    padding: 5px 20px;
    border-radius: 5px;
}

@media only screen and (max-width: 767px) 
{	
	.fronttbl .table td {
		border-top: 0px solid #dee2e6;
		min-width: 160px;
	}
}
`, "",{"version":3,"sources":["webpack://./src/app/dd-terminal/permission/permission.component.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B;AACA;CACC,0BAA0B;IACvB,oCAAoC;IACpC,sBAAsB;CACzB,iCAAiC;AAClC;AACA;IACI,cAAc;IACd,sBAAsB;IACtB,qBAAqB;AACzB;AACA;IACI,6BAA6B;AACjC;AACA;IACI,6BAA6B;AACjC;AACA;CACC,gBAAgB;AACjB;AACA;CACC,aAAa;AACd;AACA;CACC;iBACgB;CAChB,mBAAmB;IAChB,WAAW;IACX,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;;CAEC;EACC,6BAA6B;EAC7B,gBAAgB;CACjB;AACD","sourcesContent":[".fronttbl table {\n    border: 0px solid #dee2e6;\n}\n.fronttbl .table td {\n\tfont-size: 16px !important;\n    font-family: 'Open Sans', sans-serif;\n    vertical-align: middle;\n\tpadding: 25px 10px 0px !important;\n}\n.fronttbl .table .thead-dark th {\n    color: #141d38;\n    background-color: #fff;\n    border-color: #dee2e6;\n}\n.fronttbl .table td{\n    border-top: 0px solid #dee2e6;\n}\n.fronttbl .table thead th {\n    border-top: 2px solid #dee2e6;\n}\n.fronttbl .max150 {\n\tmax-width: 150px;\n}\n.fronttbl .max33{\n\twidth: 33.33%;\n}\n.defstatus{\n\t/*background: #141d38;\n    color: #fff;*/\n\tbackground: #eaeaea;\n    color: #000;\n    padding: 5px 20px;\n    border-radius: 5px;\n}\n\n@media only screen and (max-width: 767px) \n{\t\n\t.fronttbl .table td {\n\t\tborder-top: 0px solid #dee2e6;\n\t\tmin-width: 160px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
