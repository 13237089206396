// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `span.selected-item {
    margin: 1px 2px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/dd-terminal/challenge/challenge.component.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;AAC9B","sourcesContent":["span.selected-item {\n    margin: 1px 2px !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
