export namespace Constants {
  export const ERROR = "Something went wrong! Try later";
  export const FAIL_EDIT_TOPIC = "Nothing to Update! please edit something for update Content";
  export const ALERT_DEL_EXE = "Do you really want to delete exercise? if you delete this exercise then allotted exercise also delete so please be careful."
  export const ALERT_COPY_EXE = "Do you really want to copy & edit this exercise."
  export const ALERT_EXPORT_EXE = "Do you really want to export this exercise."
  export const ALERT_IMPORT_EXE = "Do you really want to import this exercise."
  export const ALERT_ALLOT_EXE = "Do you really want to allot exercise."
  export const WALLET_NOT_CREDIT = "Sorry! You don't have sufficient credits in wallet."
  export const ALERT_EXE_FIRST = "Sorry! select exercise first!"
  export const ALERT_TRAIN_GP_FIRST = "Sorry! select exercise first!"
  export const ALERT_USER_FIRST = "Sorry! select user first."
  export const ALERT_TEAM_FIRST = "Sorry! select team first."
  export const ALERT_VM_CONFIGURED = "Sorry! VM access already configured."
  export const ALERT_VM_FIRST = "Sorry! Please select VM first."
  export const ALERT_TEAM_MEM_FIRST = "Sorry! Please select team member first."
  export const ALERT_SELT_CONTROL = "Sorry! Please select control."
  export const ALERT_DEL_CONTROL = "Do you really want to remove."
  export const INSTRUCT_DONT_RES = "Instructor don't have enough resource for create new exercise."
  export const INFRA_CREATE_FAIL = "Infrastructure prepartion failed. please click on delete infra for clean and retry again."
  export const RES_CONFIG_SAVED = "Resource configuration has been successfully saved."
  export const TEMP_EXIST = "This template name aleardy used."
  export const INSTRUCT_NOT_ADD_VM = "Sorry! Instructor don't have enough VM resource to add more VM."
  export const NET_EXIST = "This network name aleardy used."
  export const INSTRUCT_NOT_ADD_NET = "Instructor don't have enough network resource."
  export const REMOVE_RES = "Do you really want to remove this resource."
  export const REMOVE_NET_RES = "Release switch from vm before remove it."
  export const INSTRUCT_NOT_RES = "Instructor can't have enough resource to add more."
  export const DEL_PREPAR_INFRA = "Do you really want to delete prepared Infra from server? here we are going only delete Infra from server."
  export const DEL_TEMP_INFRA_AND_DATA = "Do you really want to delete prepared Infra from server? here we are going to delete Infra with data from server."
  
  export const POWER_OFF_ALL = "Please power off all virtual machine from configure resources step."
  export const ALERT_ADD_RES = "You can't prepare infra. Please add resources first."
  export const ALERT_CONFIG_RES_FIRST = "You can't prepare infra. Please configure all resource first."
  export const ALERT_ADD_TEMP = "You can't prepare infra. Please add at least one template."
  export const ALERT_DEL = "Do you really want to delete this?"
  export const ALERT_UPDATE = "Do you really want to update this?"
  export const AT_LEAST_ONE_ANS = "Sorry! at least one expected answer required."
  export const ALERT_SUBMIT = "Do you really want to submit this?"
  export const ALERT_NOT_EX_NAME = "Please enter exercise name first!"
  export const EXE_CREATE_SUCCESS = "Exercises have been successfully created on the server."
  export const ALERT_EXE_CREATE = "Do you really want to create? Once the exercise is created, you can't reconfigure it."
  export const ALERT_RES_NOT_HAVE = "The instructor doesn't have enough resources for the final submission."
  export const ALERT_POWER_OFF_ALL = "Please power off all virtual machines from the configure resources step."
  export const ALERT_CPU_NOT_HAVE = "The instructor doesn't have enough vCPU resources."
  export const ALERT_RAM_NOT_HAVE = "The instructor doesn't have enough vRAM resources."
  export const ALERT_STOR_NOT_HAVE = "The instructor doesn't have enough storage resource."
  export const ALERT_SNAP_RESTO_POWER_OFF = "Do you really want to restore a snapshot? If you restore a snapshot, then the VM will be automatically powered off."
  export const ALERT_SNAP_RESTO = "Do you really want to restore a snapshot?"
  export const ALERT_SNAP_CREATE = "Do you really want to create a snapshot?"
  export const ALER_SNAP_DEL = "Do you really want to delete snapshot?"

  export const USE_OTHER_NETWORK_NAME = "Please enter another network name. The Internet/Traffic Generator is a reserved network interface."

  export const VM_RESTORE_ALL_INITAL = "Do you really want to restore all VMs at the initial stage? If you restore, then all VMs will be automatically shut down."
  export const VM_RESTORE_INITAL = "Do you really want to restore at inital stage."
  export const VM_RESTORE_SNAPSHOT = "Do you really want to restore snapshot. If you restore snapshot then VM will be automatic power off."
  export const ALERT_UPLOAD = "Do you really want to upload this?"
  export const INVALID_DURATION = 'exercise in set invalid duration.'
  export const CONFIG_ALL_VM = 'Sorry! Please configure access for all users.'
  export const ALERT_UPDATE_ALLOT = 'Do you really want to update the allotment?'
  export const ALERT_FINAL_SUBMIT_CHAL = 'Do you really want to submit? After that, you will not be able to continue this challenge.'
} 