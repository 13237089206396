// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dk-table .status-Active, .dk-table .status-Inactive {
    float: right;
}

@media only screen and (min-width: 1025px) 
{
	.dk-table table.dataTable .tidc, .dk-table .tidc {	
		width: 60px !important;
	}
}`, "",{"version":3,"sources":["webpack://./src/app/dd-terminal/referencelist/referencelist.component.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;;CAEC;EACC,sBAAsB;CACvB;AACD","sourcesContent":[".dk-table .status-Active, .dk-table .status-Inactive {\n    float: right;\n}\n\n@media only screen and (min-width: 1025px) \n{\n\t.dk-table table.dataTable .tidc, .dk-table .tidc {\t\n\t\twidth: 60px !important;\n\t}\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
