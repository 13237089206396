import { Component, OnInit, OnDestroy } from '@angular/core';
// import { LeaderboardTableComponent } from '../leaderboard-table/leaderboard-table.component';
import { Router, ActivatedRoute } from '@angular/router';
import { BackenddbService } from '../services/backenddb.service';
import { DatapassService } from '../services/datapass.service';
import { UntypedFormGroup,  UntypedFormBuilder,FormArray, UntypedFormControl, Validators  } from '@angular/forms';
import { LoginService } from '../services/login.service';
import {noWhitespaceValidator} from '../helper/validatefun';
import Swal from 'sweetalert2';
import { faHistory, faArrowRight, faArrowLeft, faCheck, faTimes, faEdit } from '@fortawesome/free-solid-svg-icons';
import { Constants } from 'src/constants';

@Component({
  selector: 'app-challengetasks',
  templateUrl: './challengetasks.component.html',
  styleUrls: ['./challengetasks.component.css']
})
export class ChallengeTasksComponent implements OnInit {

  faEdit = faEdit;
  faArrowRight = faArrowRight;
  faArrowLeft = faArrowLeft;
  faCheck = faCheck;
  faTimes= faTimes;
  faHistory = faHistory;
  active=1;
  attendId:string = '';
  quesId:string = '';
  form_title:string = 'My Challenge';
  current_url_array = [
    {'slug':"",'label':'My Challenge'}
  ];
	server_url:string;	
  progressbar:boolean = false;
  question:any = [];
  challenge:any = [];
  time:any = [];
  taskId:string = '';
  task:any = [];
	quesFrm: UntypedFormGroup;
  allotId:string = '';
  challengeId:string = '';
  trackInterval:any
  loginName:string = '';
  teamName:string = '';
  quesLoaded:boolean = false;
  challengeStatus:string = 'Pending';
  disableBtn:boolean = false;
  currentTaskIndex:number = 0;
  currentQuesIndex:number = 0;
  totalTask:number = 0;
  answer:any = [];

  error_messages = {
		'answer': [
			{ type: 'required', message: 'Answer is required' },  
		]
  }

  constructor(
    private loginService: LoginService,
    private BackenddbService:BackenddbService,
    private router: Router,
    private fb:UntypedFormBuilder,
    private ds: DatapassService, 
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
  ) { 
      this.server_url = this.loginService.getServerUrl();
      this.allotId = this.route.snapshot.paramMap.get('allotid');
      this.loginName = this.loginService.getLoginName();
      this.getAttendeeInfo()
    }

  ngOnInit(): void {
		this.quesFrm = this.formBuilder.group({
			answer: ['', [Validators.required,noWhitespaceValidator]],
		});
  }

  ngOnDestroy() {
    clearInterval(this.trackInterval);
  }

  getAttendeeInfo(loader:any = true)
  {
    this.progressbar = loader
		const formData = new FormData();
		formData.append('allot_id', this.allotId);
    formData.append('last_ques_id', this.quesId);
		this.BackenddbService.postData('challenge-api/get-allot-challenge',formData).subscribe((res:any) => {
      this.progressbar = false
		  if(res.status=='success')
			{
          this.challengeStatus = res.challengeStatus;
          this.challenge = res.challenge;
          this.task = res.task;
          this.totalTask = this.task.length
          if(this.quesLoaded == false)
          {
            this.time = res.time;
            this.attendId = res.attendId;
            this.quesLoaded = true;
            this.teamName = res.teamName;

            if(this.challengeStatus=='Pending')
            {
              this.trackTime();
            }
          }
          if(res.question.length!=0)
          {
            this.question = res.question;
            this.active = this.question.task_id;  
            this.disableBtn = false;
            this.taskId = this.question.task_id; 
            this.quesId = this.question.ques_id;
            if(this.question.correct==1)
            {
              this.disableBtn = true;
            }    
            this.setIndex();   
          }else if(this.task.length!=0)
          {
            this.active = this.task[0]['id'];
            this.taskId = this.task[0]['id']; 
          }
			}else
      {
        Swal.fire('Oops...', res.message, 'error');
      }
		});
  }


  trackTime()
  {
    this.trackInterval = setInterval(() => { 
      const formData = new FormData();
      formData.append('attend_id',this.attendId);
      this.BackenddbService.postData('challenge-api/track-challenge-time',formData).subscribe((res:any) => {
        if(res.status=='success')
        {
           if(res.challengeStatus=='Pending')
           {
              this.time.used_time = res.usedTime;
              this.time.remain_time = res.remainTime;
           }else
           {
             this.challengeStatus = res.challengeStatus;
             Swal.fire('Oops...','The challenge time duration is over.', 'warning');
           }
        }
      })
 
      }, 1000*60);
  }

  chooseQuestion(taskIndex:any,quesIndex:any)
  {
      this.question = this.task[taskIndex]['question'][quesIndex];
      this.taskId = this.task[taskIndex]['id']
      this.quesId = this.question.ques_id;
      this.quesFrm.get('answer').patchValue('');
      this.disableBtn = false;
      if(this.question.correct==1)
      {
        this.disableBtn = true;
      }
      this.setIndex()
  }

  submitQues(data:any)
  {
		if(this.quesFrm.valid) 
		{
      this.progressbar = true
      const formData = new FormData();
      formData.append('attend_id',this.attendId);
      formData.append('ques_id',this.question.ques_id);
      formData.append('answer',data.answer);
      this.BackenddbService.postData('challenge-api/submit-question',formData).subscribe((res:any) => {
        if(res.status=='success')
        {
          this.getAttendeeInfo(true);
          this.quesFrm.get('answer').patchValue('');
        }else
        {        
          this.progressbar = false;
          if(typeof res.challengeStatus != 'undefined' && res.challengeStatus=='Completed')
          {
            this.challengeStatus  = res.challengeStatus
          }          
          Swal.fire('Oops...', res.message, 'error');
        }
      });
    }
		else
		{
			this.validateAllFormFields(this.quesFrm)
		}
  }

  public getAnswerHistory()
  {
    this.progressbar = true
    const formData = new FormData();
    formData.append('attend_id',this.attendId);
    formData.append('id',this.question.id);
    this.BackenddbService.postData('challenge-api/get-question-history',formData).subscribe((res:any) => {
      if(res.status=='success')
      {
         this.progressbar = false;
         this.answer = res.data;
         (<any>$('#quesModal')).modal('show');
      }
    })  
  }


  public setIndex()
  {
      let taskIndex = this.task.findIndex((obj:any,index:any)=>{
          return obj.id==this.taskId
      })
      if(taskIndex>=0)
      {
          let quesIndex = this.task[taskIndex]['question'].findIndex((obj:any,index:any)=>{
            return obj.ques_id==this.quesId
          })
          this.currentTaskIndex = taskIndex
          this.currentQuesIndex = quesIndex
      }
  }

  public nextPrevQues(step:any)
  {
    let quesLength = this.task[this.currentTaskIndex]['question'].length-1
    if(this.currentQuesIndex < quesLength)
    {
        if(step=='next')
        {
          var nextQuesIndex = this.currentQuesIndex+1
        }else
        {
          var nextQuesIndex = this.currentQuesIndex-1
          if(this.currentTaskIndex > 0 && nextQuesIndex < 0)
          {
            var nextQuesIndex = 0
            this.currentTaskIndex = this.currentTaskIndex-1
          }
        }
        this.chooseQuestion(this.currentTaskIndex,nextQuesIndex)
    }
    else if(this.currentQuesIndex == quesLength)
    {
       var nextQuesIndex = this.currentQuesIndex-1
       if(step=='next')
       {
          var nextTaskIndex = this.currentTaskIndex+1
          if(nextTaskIndex==this.totalTask)
          {
            nextTaskIndex = 0
          }
          nextQuesIndex = 0
       }else
       {
          if(this.currentTaskIndex>0)
          {
            var nextTaskIndex = this.currentTaskIndex
            nextQuesIndex = this.currentQuesIndex-1

          }else
          {
            var nextTaskIndex = this.currentTaskIndex-1
          }
       }

       if(typeof this.task[nextTaskIndex] != 'undefined')
       {
          this.chooseQuestion(nextTaskIndex,nextQuesIndex)
       }else
       {
          this.chooseQuestion(0,nextQuesIndex)
       }
    }
  }


  public finalSubmit()
  {
    Swal.fire({
      title: 'Are you sure?',
      text: Constants.ALERT_FINAL_SUBMIT_CHAL,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'OK',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if(result.value) {

        this.progressbar = true
        const formData = new FormData();
        formData.append('attend_id',this.attendId);
        this.BackenddbService.postData('challenge-api/final-submit',formData).subscribe((res:any) => {
          this.progressbar = false;
          if(res.status=='success')
          {
             this.challengeStatus = res.challengeStatus;
          }
        })  
       
      }
    })
    .catch(() => 
       console.log('Cancel') 
    );
  }
  

  get f() { return this.quesFrm.controls; }

	validateAllFormFields(formGroup: UntypedFormGroup) {
		Object.keys(formGroup.controls).forEach(field => {
			const control = formGroup.get(field);
			if (control instanceof UntypedFormControl) {
				control.markAsTouched({ onlySelf: true });
			} else if (control instanceof UntypedFormGroup) {
				this.validateAllFormFields(control);
			}
		});
	}
}
