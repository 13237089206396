// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-box-shadow-sec {
    padding: 0 20px 11px !important;
}

`, "",{"version":3,"sources":["webpack://./src/app/dd-terminal/content/content.component.css"],"names":[],"mappings":"AAAA;IACI,+BAA+B;AACnC","sourcesContent":[".content-box-shadow-sec {\n    padding: 0 20px 11px !important;\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
