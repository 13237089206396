// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dk-table table.dataTable .tuidc, .dk-table .tuidc {
    min-width: 130px !important;
}
.datetime{
    width:150px;
}
span.date {
    width: 95px;
}`, "",{"version":3,"sources":["webpack://./src/app/dd-terminal/userlist/userlist.component.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;AAC/B;AACA;IACI,WAAW;AACf;AACA;IACI,WAAW;AACf","sourcesContent":[".dk-table table.dataTable .tuidc, .dk-table .tuidc {\n    min-width: 130px !important;\n}\n.datetime{\n    width:150px;\n}\nspan.date {\n    width: 95px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
