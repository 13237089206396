// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table{
    margin-bottom: 5rem;
}
thead{
  font-size: 1.25rem;
  font-weight: bold;
  padding: 10px;
  text-align: center;
  background-color: darkorchid;
  color: #fff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}`, "",{"version":3,"sources":["webpack://./src/app/leaderboard/leaderboard.component.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;AACA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,aAAa;EACb,kBAAkB;EAClB,4BAA4B;EAC5B,WAAW;EACX,2CAA2C;EAC3C,2CAA2C;AAC7C","sourcesContent":["table{\n    margin-bottom: 5rem;\n}\nthead{\n  font-size: 1.25rem;\n  font-weight: bold;\n  padding: 10px;\n  text-align: center;\n  background-color: darkorchid;\n  color: #fff;\n  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);\n  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
