// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-section {
    background-color: #0060ff;
    padding: 13px 30px;
	color: #fff;
}
.footerright {
	text-align: right;
}
.footerleft, .footerright {
	padding: 0;
}
.footer-section a{
	color: #fff;
}
.footer-section a:hover {
	color: #c9ddff;
}

@media only screen and (max-width: 768px)
{
	.footerleft, .footerright {
		text-align: left;
	}
	.footer-section {
		padding: 13px 20px;
	}
}`, "",{"version":3,"sources":["webpack://./src/app/dd-terminal/footer/footer.component.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,kBAAkB;CACrB,WAAW;AACZ;AACA;CACC,iBAAiB;AAClB;AACA;CACC,UAAU;AACX;AACA;CACC,WAAW;AACZ;AACA;CACC,cAAc;AACf;;AAEA;;CAEC;EACC,gBAAgB;CACjB;CACA;EACC,kBAAkB;CACnB;AACD","sourcesContent":[".footer-section {\n    background-color: #0060ff;\n    padding: 13px 30px;\n\tcolor: #fff;\n}\n.footerright {\n\ttext-align: right;\n}\n.footerleft, .footerright {\n\tpadding: 0;\n}\n.footer-section a{\n\tcolor: #fff;\n}\n.footer-section a:hover {\n\tcolor: #c9ddff;\n}\n\n@media only screen and (max-width: 768px)\n{\n\t.footerleft, .footerright {\n\t\ttext-align: left;\n\t}\n\t.footer-section {\n\t\tpadding: 13px 20px;\n\t}\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
