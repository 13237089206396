// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.net-topology {
    position: relative;
    width: 100%;
    height: 100%;
}
.action-menu{
    display: none;
    z-index: 1000;
    position: absolute;
}
.custom-menu {
    background-color: #fff;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}
#mynetwork {
    width: 100%;
    height: 500px;
   /* border: 1px solid lightgray;*/
}
.frontend.mynetwork {
    width: 100%;
    height: 700px !important;
}
img.img-profile {
    width: 70px;
    height: 70px;
    border-radius: 100%;
}
.custom-menu.frontend {
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/app/dd-terminal/viewnetwork/viewnetwork.component.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;AAChB;AACA;IACI,aAAa;IACb,aAAa;IACb,kBAAkB;AACtB;AACA;IACI,sBAAsB;IACtB,aAAa;IACb,sBAAsB;IACtB,kBAAkB;AACtB;AACA;IACI,WAAW;IACX,aAAa;GACd,gCAAgC;AACnC;AACA;IACI,WAAW;IACX,wBAAwB;AAC5B;AACA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;AACvB;AACA;IACI,kBAAkB;AACtB","sourcesContent":[".net-topology {\n    position: relative;\n    width: 100%;\n    height: 100%;\n}\n.action-menu{\n    display: none;\n    z-index: 1000;\n    position: absolute;\n}\n.custom-menu {\n    background-color: #fff;\n    padding: 10px;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n}\n#mynetwork {\n    width: 100%;\n    height: 500px;\n   /* border: 1px solid lightgray;*/\n}\n.frontend.mynetwork {\n    width: 100%;\n    height: 700px !important;\n}\nimg.img-profile {\n    width: 70px;\n    height: 70px;\n    border-radius: 100%;\n}\n.custom-menu.frontend {\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
