import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

import { DatapassService } from '../../services/datapass.service';
declare const activesidebar:any;
@Component({
  selector: 'app-purchasevoucherlist',
  templateUrl: './purchasevoucherlist.component.html',
  styleUrls: ['./purchasevoucherlist.component.css']
})
export class PurchasevoucherlistComponent implements OnInit {
  resetHeader : Subject<any> = new Subject<any>(); // admin header

  current_url_array = [
    {'slug':"",'label':'Purchased Voucher'},
    ];
  form_title:string = 'Purchased Voucher';

  public _opened: boolean = true; 
	public _toggleSidebar() {
		this._opened = !this._opened;
	}  	
  constructor( private ds: DatapassService) 
  {

   }

  ngOnInit(): void {

    activesidebar(); 

  }
	callResetHeader(event:any)
	{
       this.ds.sendData('wallet');
      //this.resetHeader.next('resetWallet');
	}
}
