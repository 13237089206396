// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.min80 {
    min-width: 95px !important;
}
.Private {
    color: #132144;
    font-family: 'OpenSans-SemiBold', sans-serif;
    background: #346eff7a;
    padding: 4px 10px;
    border-radius: 8px;
    min-width: 100px;
    display: inline-block;
    text-align: center;
}

.container {
    display: flex;
  }
  
  .element {
    margin-left: auto;
  }`, "",{"version":3,"sources":["webpack://./src/app/dd-terminal/exerciselist/exerciselist.component.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;AAC9B;AACA;IACI,cAAc;IACd,4CAA4C;IAC5C,qBAAqB;IACrB,iBAAiB;IACjB,kBAAkB;IAClB,gBAAgB;IAChB,qBAAqB;IACrB,kBAAkB;AACtB;;AAEA;IACI,aAAa;EACf;;EAEA;IACE,iBAAiB;EACnB","sourcesContent":[".min80 {\n    min-width: 95px !important;\n}\n.Private {\n    color: #132144;\n    font-family: 'OpenSans-SemiBold', sans-serif;\n    background: #346eff7a;\n    padding: 4px 10px;\n    border-radius: 8px;\n    min-width: 100px;\n    display: inline-block;\n    text-align: center;\n}\n\n.container {\n    display: flex;\n  }\n  \n  .element {\n    margin-left: auto;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
