import { Component, OnInit } from '@angular/core';
import { CandidateheaderComponent } from '../candidate/candidateheader/candidateheader.component';
import { CandidatefooterComponent } from '../candidate/candidatefooter/candidatefooter.component';
import { BreadcrumbsComponent } from '../breadcrumbs/breadcrumbs.component';


@Component({
  selector: 'app-leaderboard',
  templateUrl: './leaderboard.component.html',
  styleUrls: ['./leaderboard.component.css'],
  
})
export class LeaderboardComponent implements OnInit {

  active=1;

  //breadcrumbs
  form_title:string = ''; 
  current_url_array = [];

  constructor() { 
    this.form_title = "Leaderboard";
  }

  ngOnInit(): void {
    this.current_url_array = [
		  {"slug":"","label":"Leaderboard"}
		] 
  }

}
