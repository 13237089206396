// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.technical_team img{
    margin-bottom: 10px;
}
.our-team .social .sa {
    margin-right: 10px;
}
.our-team .social .sa :last-child {
    margin-right: 0px;
}
@media only screen and (min-device-width: 1024px) and (max-device-width: 1200px)
{
    .technical_team .tt-20 {
        flex: 0 0 33.33%;
        max-width: 33.33%;
    }    
}
@media only screen and (min-width: 1300px)
{
    .technical_team .tt-20 {
        flex: 0 0 20%;
        max-width: 20%;
    }    
}

`, "",{"version":3,"sources":["webpack://./src/app/ourteam/ourteam.component.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;AACA;IACI,kBAAkB;AACtB;AACA;IACI,iBAAiB;AACrB;AACA;;IAEI;QACI,gBAAgB;QAChB,iBAAiB;IACrB;AACJ;AACA;;IAEI;QACI,aAAa;QACb,cAAc;IAClB;AACJ","sourcesContent":[".technical_team img{\n    margin-bottom: 10px;\n}\n.our-team .social .sa {\n    margin-right: 10px;\n}\n.our-team .social .sa :last-child {\n    margin-right: 0px;\n}\n@media only screen and (min-device-width: 1024px) and (max-device-width: 1200px)\n{\n    .technical_team .tt-20 {\n        flex: 0 0 33.33%;\n        max-width: 33.33%;\n    }    \n}\n@media only screen and (min-width: 1300px)\n{\n    .technical_team .tt-20 {\n        flex: 0 0 20%;\n        max-width: 20%;\n    }    \n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
