import { Component, OnInit,ViewChild } from '@angular/core';
import { UntypedFormGroup,  UntypedFormBuilder, UntypedFormControl, Validators  } from '@angular/forms';
import { LoginService } from '../../services/login.service';
import { faFile, faEdit, faTrash, faSort, faPlus } from '@fortawesome/free-solid-svg-icons'; 
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { environment } from '../../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import {noWhitespaceValidator,checkEmptyArray,digitValidator} from '../../helper/validatefun';
//import {mustMatch} from '../../helper/confirmed.validator';
import { BackenddbService } from '../../services/backenddb.service';
import { DatapassService } from '../../services/datapass.service';
import Swal from 'sweetalert2';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import { Constants } from 'src/constants';

declare const alertfun:any;
declare const activesidebar:any;

@Component({
  selector: 'app-challenge',
  templateUrl: './challenge.component.html',
  styleUrls: ['./challenge.component.css']
})
export class ChallengeComponent implements OnInit {
	faFile = faFile;
	faEdit = faEdit;
	faTrash = faTrash;	
	faSort = faSort;
	faPlus = faPlus
	submitted: boolean = false;
	showTopicForm:boolean=false;
	public _opened: boolean = true; 
	progress_message:string='';
	serverUrl = environment.baseUrl;
	public _toggleSidebar() {
		this._opened = !this._opened;
	}
	public personaldetails = [];
	messageArray = {  
		type: "",  
		message: "",  
	  }; 
	man_frm: UntypedFormGroup;
	task_frm : UntypedFormGroup;
	ques_frm : UntypedFormGroup;
	msgalert:any;
	stringifiedData: any; 
	keyword_array = []; 
	// error validation message
	error_messages = {
		'm_title': [
			{ type: 'required', message: 'Challenge is required' },  
		],
		'status': [
			{ type: 'required', message: 'Status is required' },  
		],
		'title': [
			{ type: 'required', message: 'Title is required' },  
		],
		'question': [
			{ type: 'required', message: 'Question is required' },  
		],
		'ques_type': [
			{ type: 'required', message: 'Question Type is required' },  
		],
		'ques_status': [
			{ type: 'required', message: 'Question Status is required' },  
		],
		'option_a': [
			{ type: 'required', message: 'Option A is required' },  
		],
		'option_b': [
			{ type: 'required', message: 'Option B is required' },  
		],
		'option_c': [
			{ type: 'required', message: 'Option C is required' },  
		],
		'option_d': [
			{ type: 'required', message: 'Option D is required' },  
		],
		'answer': [
			{ type: 'required', message: 'Answer is required' },  
		],
		// 'answerList': [
		// 	{ type: 'required', message: 'Add expected answer' },  
		// ],
		'option_answer': [
			{ type: 'required', message: 'Answer is required' },  
		],
		'marks': [
			{ type: 'required', message: 'Marks is required' },  
			{ type: 'digitValidator', message: 'Invalid marks entered' },  
		],
	} 
	option:any = ['A','B','C','D']
	placeholder = "Keywords"
	sec_placeholder = "Keywords"
	// alert box	
	error: string;
	chall_id:string='';
	group_id:string = '';
	t_title:string='';
	closed: boolean = true;
	alertMessage: string;
	alertClass: string;
	last_insert_id:'';
	edit_tid:string='';
	index :string='1';
	message:string;
	topicMessage:string;
	notification:boolean=false;
	topic_btn:string='Save';
	notification_topic:boolean=false;
	Topicbtn:boolean=false;
	topicslist :Object;
	dashboard_url:string;
	manual_title:string;
	/*breadcrumbs array */
	current_url_array = [];
	//form_title:any;
	form_title = "Challenge";
	server_url:string;
	d_desc_placeholder = "Description";
	d_desc:string = '';
	config: AngularEditorConfig ;
	@ViewChild('auto') auto:any;
	public instructor_list = []; // store instuctor
	keyword = 'name';
	initial_value:string = ""; //  default selected value
	instructor_selected:string = ""; 
	limit_start:any = 0; // start limit in select query
	update_by:string = "";
	@ViewChild('multiSelect') manual_dropdown;
	exDropdownList:any = [];
	exItems:any = [];
	exDropdownSettings = {};
	exKeyword:any = "";
	exList:any;
	exArray:any = [];
	assetList:any = [];
	assetItems:any = [];
	assetKeyword:any = "";
	assetArray:any = [];
	statusList:any = []
	showModal: boolean = false
	showQuesModal: boolean = false
	task:any = []
	currentTaskId:any = ''
	currentQuesId:any = ''
	optionShow: boolean = false
	answerList:any = []
	ansError:string = ''
	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private loginService: LoginService, 
		private formBuilder: UntypedFormBuilder,
		private BackenddbService:BackenddbService,
		private ds: DatapassService
		)
		{ 
			this.getFlashMessage();
			this.server_url = this.loginService.getServerUrl();
			this.dashboard_url = this.loginService.getDashboardUrl();
			this.group_id = this.loginService.getLoginGroup();
			this.update_by = this.loginService.getUserId(); 
    	}
	ngOnInit(): void {
		alertfun()
		this.dropdown_setting()
		this.loadData()
		this.getExerciceList()
		this.chall_id = this.route.snapshot.paramMap.get('id');
		activesidebar()
		/* Form validation */
		this.man_frm = this.formBuilder.group({
			m_title: [null, [Validators.required,noWhitespaceValidator]],
			d_desc: [null,],
			exKeyword: [null],
			keyword: [null],
			status: ['Active', [Validators.required]],
		});
		this.task_frm = this.formBuilder.group({
			title: [null, [Validators.required,noWhitespaceValidator]],
			desc: [null,],
			status: ['Active', [Validators.required]],
		});
		this.ques_frm = this.formBuilder.group({
			ques_type: ['No', [Validators.required]],
			question: ['', [Validators.required]],
			option_a: ['', [noWhitespaceValidator]],
			option_b: ['', [noWhitespaceValidator]],
			option_c: ['', [noWhitespaceValidator]],
			option_d: ['', [noWhitespaceValidator]],
			option_answer: ['A', [Validators.required]],
			marks: ['', [Validators.required,noWhitespaceValidator,digitValidator]],
			ques_status: ['Active', [Validators.required]],
			answer: [''],
			answerList: [[],[checkEmptyArray]],
			assetKeyword: [''],
		});
		this.ques_frm.get('ques_type').valueChanges.subscribe(response => {
			if(response == "Yes"){
				this.ques_frm.get('option_a').setValidators(Validators.required);
				this.ques_frm.get('option_a').updateValueAndValidity();
				this.ques_frm.get('option_b').setValidators(Validators.required);
				this.ques_frm.get('option_b').updateValueAndValidity();
				this.ques_frm.get('option_c').setValidators(Validators.required);
				this.ques_frm.get('option_c').updateValueAndValidity();
				this.ques_frm.get('option_d').setValidators(Validators.required);
				this.ques_frm.get('option_d').updateValueAndValidity();
				this.ques_frm.get('option_answer').setValidators(Validators.required);
				this.ques_frm.get('option_answer').updateValueAndValidity();
				//this.ques_frm.get('answer').clearValidators();
			//	this.ques_frm.get('answer').updateValueAndValidity();
				//this.ques_frm.get('answerList').clearValidators();
				//this.ques_frm.get('answerList').updateValueAndValidity();
			} else {
				this.ques_frm.get('option_a').clearValidators();
				this.ques_frm.get('option_a').updateValueAndValidity();
				this.ques_frm.get('option_b').clearValidators();
				this.ques_frm.get('option_b').updateValueAndValidity();
				this.ques_frm.get('option_c').clearValidators();
				this.ques_frm.get('option_c').updateValueAndValidity();
				this.ques_frm.get('option_d').clearValidators();
				this.ques_frm.get('option_d').updateValueAndValidity();
				this.ques_frm.get('option_answer').clearValidators();
				this.ques_frm.get('option_answer').updateValueAndValidity();
			//	this.ques_frm.get('answer').setValidators(Validators.required);
				//this.ques_frm.get('answer').updateValueAndValidity();
				//this.ques_frm.get('answerList').setValidators(checkEmptyArray);
				//this.ques_frm.get('answerList').updateValueAndValidity();
			}
		  })
		this.BackenddbService.getStatusList().subscribe((data:any) => {
			this.statusList = Array.from(Object.keys(data), k=>data[k]);
	    });  
		if(this.chall_id!=null)
		{
			this.getChallenge()
		}
		this.current_url_array = [
			{'slug':"",'label':'Manual'}
		];
	}
	dropdown_setting()
	{
	  this.exDropdownSettings = {
		singleSelection: false,
		idField: 'id',
		textField: 'name',
		selectAllText: 'Select All',
		unSelectAllText: 'UnSelect All',
		itemsShowLimit: 10,
		allowSearchFilter: true,
		allowRemoteDataSearch:true,
		limitSelection:10
	  };
	}  
	// validation check here 
	validateAllFormFields(formGroup: UntypedFormGroup) {
		Object.keys(formGroup.controls).forEach(field => {
			const control = formGroup.get(field);
			if (control instanceof UntypedFormControl) {
				control.markAsTouched({ onlySelf: true });
			} else if (control instanceof UntypedFormGroup) {
				this.validateAllFormFields(control);
			}
		});
	}
	getChallenge()
	{
		this.ds.Loader(true)
		const formData = new FormData()
		formData.append('id',this.chall_id)
		let api = 'challenge-api/get-challenge'
		this.BackenddbService.postData(api,formData).subscribe(
			res => {
				this.ds.Loader(false)
				if(res.status=='success')
				{
					let data = res.data
					this.exArray = data.relevent_exe_id 
					this.task = data.task
					this.assetList = data.asset
					this.man_frm.patchValue({
						m_title: data.name,
						d_desc: data.description,
						exKeyword: data.relevent_exe,
						status: data.status,
					})
				}else
				{
					this.setFlashMessage('success',res.message);
					this.router.navigate([this.dashboard_url+'challenge']);
				}
			})
	}
	onSubmit(data:any) 
	{
		if(this.man_frm.valid) 
		{
			this.ds.Loader(true)
			const formData = new FormData()
			let api = 'challenge-api/add-challenge'
			if(this.chall_id!=null)
			{
				api = 'challenge-api/update-challenge'
				formData.append('id',this.chall_id)
			}
			formData.append('name',data.m_title)
			let desc = ''
			if(data.d_desc!=null)
			{
			   desc = data.d_desc
			}
			formData.append('description',desc)
			formData.append('relevent_exe',JSON.stringify(this.exArray))
			formData.append('status',data.status)
			this.BackenddbService.postData(api,formData).subscribe(
				res => {
					this.ds.Loader(false)
					if(res.status == 'success')
					{
						if(this.chall_id==null)
						{
							this.setFlashMessage('success',res.message)
						}else
						{
							this.alertClass = 'success';
							this.alertMessage = res.message;
							this.closed = false;
						}
						this.router.navigate([this.dashboard_url+'challenge/'+res.last_insert_id])
					}
					else
					{
						this.alertClass = 'danger';
						this.alertMessage = res.message;
						this.closed = false;
					}
				});
		}
		else
		{
			this.validateAllFormFields(this.man_frm)
		}
	}
	onTaskSubmit(data:any) 
	{
		if(this.task_frm.valid) 
		{
			this.ds.Loader(true)
			const formData = new FormData()
			let api = 'challenge-api/add-task'
			if(this.currentTaskId!='')
			{
				api = 'challenge-api/edit-task'
				formData.append('id',this.currentTaskId)
			}
			formData.append('chall_id',this.chall_id)
			formData.append('title',data.title)
			formData.append('description',data.desc)
			formData.append('status',data.status)
			this.BackenddbService.postData(api,formData).subscribe(
				res => {
					this.alertClass = 'danger';
					if(res.status=='success')
					{
						this.alertClass = 'success';
					}
					this.alertMessage = res.message;
					this.closed = false;
					this.showModal = false
					this.getChallenge()
				});
		}
		else
		{
			this.validateAllFormFields(this.task_frm)
		}
	}
	onQuesSubmit(data:any) 
	{
			if(this.ques_frm.valid && this.answerList.length!=0) 
			{
				this.ds.Loader(true);
				const formData = new FormData();
				let api = 'challenge-api/add-question'
				if(this.currentQuesId!='')
				{
					api = 'challenge-api/edit-question'
					formData.append('id',this.currentQuesId)
				}else
				{
					formData.append('task_id',this.currentTaskId)
				}
			
				formData.append('question',data.question)
				formData.append('ques_type',data.ques_type)
				// formData.append('option_a',data.option_a)
				// formData.append('option_b',data.option_b)
				// formData.append('option_c',data.option_c)
				// formData.append('option_d',data.option_d)
				//formData.append('option_answer',data.option_answer)
				formData.append('marks',data.marks)
				formData.append('status',data.ques_status)
				if(data.ques_type=='No')
				{
				  formData.append('answer',JSON.stringify(this.answerList))
				}
				//formData.append('asset',JSON.stringify(this.assetArray))
				this.BackenddbService.postData(api,formData).subscribe(
					res => {
						this.currentQuesId = ''
						this.alertClass = 'danger';
						if(res.status=='success')
						{
							this.alertClass = 'success';
						}
						this.alertMessage = res.message;
						this.closed = false;
						this.showQuesModal = false
						this.getChallenge()
					});
			}
			else
			{
				if(this.answerList.length==0)
				{
				  this.ansError = 'Add expected answer'
				}
				this.validateAllFormFields(this.ques_frm);
			}
	}
	editTask(id:any)
	{
		this.notification = false
		this.currentTaskId = ''
		this.currentQuesId = ''
		let result:any = this.task.find((obj:any)=>{
            return obj.id == id
		})
        if(typeof result !== undefined)
		{
			this.task_frm.patchValue({
				title: result.task_name,
				desc: result.description,
				status: result.status,
			})
			this.showModal = true
			this.currentTaskId = id
		}
	}
	addTask()
	{
		this.currentTaskId = ''
		this.notification = false
		this.task_frm.patchValue({
			title: '',
			desc: '',
			status: 'Active',
		})
		this.showModal = true
	}	
	resetForm()
	{
		this.ques_frm.patchValue({
			ques_type: 'No',
			question: '',
			option_a: '',
			option_b: '',
			option_c: '',
			option_d: '',
			option_answer: 'A',
			marks: null,
			ques_status: 'Active',
			answer: null,
			assetKeyword:''
		});
		this.answerList = []
	} 
	addQuesModal(id:any)
	{
		this.currentTaskId = id
		this.optionShow = false
		this.closed = true
		this.notification=false
		this.resetForm()
		this.showQuesModal = true
	}
	addAnswer(data:any)
	{  
		if(data.answer=="" || data.answer == null)
		{
			return true;
		}
		let ind = this.answerList.findIndex((obj:any,index:any)=>{
            return obj.answer == data.answer
		})
		if(ind<0)
		{
			this.answerList.push({id:'',answer:data.answer})
			this.ques_frm.get('answer').patchValue('');
		}
	}
	deleteQuestion(id:any)
	{
			Swal.fire({
				title: 'Are you sure?',
				text: Constants.ALERT_DEL,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'OK',
				cancelButtonText: 'Cancel'
			}).then((result) => {
				if(result.value) {
					this.ds.Loader(true)
					const formData = new FormData()
					let api = 'challenge-api/delete-question'
					formData.append('id',id)
					this.BackenddbService.postData(api,formData).subscribe(
						res => {
							this.alertClass = 'danger';
							if(res.status=='success')
							{
								this.alertClass = 'success';
							}
							this.alertMessage = res.message;
							this.closed = false;
							this.getChallenge()
					})
		        }
		})
	}
	deleteTask(id:any)
	{
		Swal.fire({
			title: 'Are you sure?',
			text: Constants.ALERT_DEL,
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'OK',
			cancelButtonText: 'Cancel'
		}).then((result) => {
			if(result.value) {
				this.ds.Loader(true)
				const formData = new FormData()
				let api = 'challenge-api/delete-task'
				formData.append('id',id)
				this.BackenddbService.postData(api,formData).subscribe(
					res => {
						this.ds.Loader(false)
						this.alertClass = 'danger';
						if(res.status=='success')
						{
							this.alertClass = 'success';
						}
						this.alertMessage = res.message;
						this.closed = false;
						this.getChallenge()
					})
			}
		})
	}

	deleteAnswer(id:any,index:any)
	{
	   if(this.answerList.length==1)
	   {
		  Swal.fire('Oops...', Constants.AT_LEAST_ONE_ANS, 'warning');
		  return true
	   }
       if(id!="")
	   {
			Swal.fire({
				title: 'Are you sure?',
				text: Constants.ALERT_DEL,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'OK',
				cancelButtonText: 'Cancel'
			}).then((result) => {
				if(result.value) {
					this.ds.Loader(true)
					const formData = new FormData()
					let api = 'challenge-api/delete-answer'
					formData.append('id',id)
					this.BackenddbService.postData(api,formData).subscribe(
						res => {
							this.ds.Loader(false)
							this.alertClass = 'danger';
							if(res.status=='success')
							{
								this.alertClass = 'success';
								this.answerList.splice(index,1)
							}
							this.alertMessage = res.message;
							this.closed = false;
					})
				}
			})
	   }else
	   {
	     this.answerList.splice(index,1)
	   }
	}
	quesTypeChange(event:any)
	{
	  if(event.target.value=='Yes')
	  {
         this.optionShow = true
	  }else
	  {
		 this.optionShow = false
	  }
	}
	getExerciceList()
	{
		let api = 'challenge-api/get-exercise-list'
		this.BackenddbService.getData(api).subscribe(
			res => {
				this.exList = res
				console.log(res)
		});
	}
	onItemSelect(item:any,type:any)
	{
		if(type=='Exercise')
		{
		  this.exArray.push(item.id)
		  console.log(this.exArray)
		}else
		{
		  this.assetArray.push(item.id)
		}
	}
	onItemDeSelect(item:any,type:any)
	{
		if(type=='Exercise')
		{
			const index = this.exArray.indexOf(item.id);
			if (index > -1) {
				this.exArray.splice(index, 1);
			}
			console.log('Remove')
			console.log(this.exArray)
		}else
		{
			const index = this.assetArray.indexOf(item.id);
			if (index > -1) {
				this.assetArray.splice(index, 1);
			}
		}
	}
	getFlashMessage()
	{
	  this.msgalert = this.loginService.getflashMessage();
	  if (typeof this.msgalert.type != "undefined")
	  {
		this.alertClass = this.msgalert.type;
		this.alertMessage = this.msgalert.message;
		this.closed = false;
	  }
	}
	setFlashMessage(type:any,message:any)
	{
		this.messageArray.type = type;
		this.messageArray.message = message;
		this.stringifiedData = JSON.stringify(this.messageArray);   
		this.loginService.setflashMessage(this.stringifiedData);
	}
	saveOrder(index:any)
	{
		console.log(this.task[index]['question'])
		this.ds.Loader(true);
		const formData = new FormData();
		formData.append('question',JSON.stringify(this.task[index]['question']));
		var api = 'challenge-api/save-question-order';
		this.BackenddbService.postData(api,formData).subscribe(
			res => {
				this.ds.Loader(false);
				console.log(res)
			})	
	}
	editQuestion(id:any)
	{
		this.notification = false
		this.ds.Loader(true)
		this.resetForm()
		const formData = new FormData()
		formData.append('id',id)
		let api = 'challenge-api/get-question'
		this.BackenddbService.postData(api,formData).subscribe(
			res => {
				console.log(res)
				this.ds.Loader(false)
				if(res.status=='success')
				{
					let ques = res.data.question
					this.answerList = res.data.answer
					this.currentQuesId = ques.id
					this.ques_frm.patchValue({
						ques_type: ques.ques_type,
						question: ques.question,
    					marks: ques.marks,
						ques_status: ques.status,
					});
					this.showQuesModal = true
				}
		})			
	}
    onDrop(event: CdkDragDrop<string[]>,index:any) 
	{
		if (event.previousContainer === event.container) {
		moveItemInArray(event.container.data, 
			event.previousIndex, event.currentIndex);
		} else {
		transferArrayItem(event.previousContainer.data,
		event.container.data,
		event.previousIndex,
		event.currentIndex);
		}
		this.saveOrder(index);
	}
	set_permission(data:any)
	{
		if(data.permission.create_manual=='Denied')
		{
			this.router.navigate([this.dashboard_url+'dashboard']);
		}
	}
	hide() {
		this.showModal = false
		this.showQuesModal = false
	}
	loadData()
	{
		var array = ['checkClaim','2',''];
		this.ds.sendData(array);
		this.ds.getData().subscribe(x => { 
			if(x[0]=='set_permission')
			{
			  this.set_permission(x[1]);
			}
		  });
	}
	get f() { return this.man_frm.controls; }
}