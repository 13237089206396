// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*.contactc {
    position: relative;
    padding: 30px 30px;
    box-shadow: 0px 0px 20px rgba(0,0,0,0.10);
}*/
.contactc {
    padding: 30px 30px 25px;
    box-shadow: 0px 0px 20px rgba(0,0,0,0.10);
    /*max-width: 335px;
	display: block;
    margin: auto;*/
	margin-bottom: 25px;
}
.cmauto {
	display: block;
    margin: auto;
}
.contactc .contactcicon { 
	float: left;
    width: 58px;
    line-height: 0;
}
.contactc .contactcnt {
	display: grid;
}
.contactc .faci {
	font-size: 40px;
	color: #0060ff;
}
.contactus .git {
	color: #0060ff;
}
.contactus p, .contactus a{
	color: #212529;
}
.contactus a:hover{
	color: #0060ff;
}
.mtf {
	margin-top: 20px;
}
.map {
	box-shadow: 0px 0px 20px rgba(0,0,0,0.10);
    padding: 4px;
} `, "",{"version":3,"sources":["webpack://./src/app/contactus/contactus.component.css"],"names":[],"mappings":"AAAA;;;;EAIE;AACF;IACI,uBAAuB;IACvB,yCAAyC;IACzC;;kBAEc;CACjB,mBAAmB;AACpB;AACA;CACC,cAAc;IACX,YAAY;AAChB;AACA;CACC,WAAW;IACR,WAAW;IACX,cAAc;AAClB;AACA;CACC,aAAa;AACd;AACA;CACC,eAAe;CACf,cAAc;AACf;AACA;CACC,cAAc;AACf;AACA;CACC,cAAc;AACf;AACA;CACC,cAAc;AACf;AACA;CACC,gBAAgB;AACjB;AACA;CACC,yCAAyC;IACtC,YAAY;AAChB","sourcesContent":["/*.contactc {\n    position: relative;\n    padding: 30px 30px;\n    box-shadow: 0px 0px 20px rgba(0,0,0,0.10);\n}*/\n.contactc {\n    padding: 30px 30px 25px;\n    box-shadow: 0px 0px 20px rgba(0,0,0,0.10);\n    /*max-width: 335px;\n\tdisplay: block;\n    margin: auto;*/\n\tmargin-bottom: 25px;\n}\n.cmauto {\n\tdisplay: block;\n    margin: auto;\n}\n.contactc .contactcicon { \n\tfloat: left;\n    width: 58px;\n    line-height: 0;\n}\n.contactc .contactcnt {\n\tdisplay: grid;\n}\n.contactc .faci {\n\tfont-size: 40px;\n\tcolor: #0060ff;\n}\n.contactus .git {\n\tcolor: #0060ff;\n}\n.contactus p, .contactus a{\n\tcolor: #212529;\n}\n.contactus a:hover{\n\tcolor: #0060ff;\n}\n.mtf {\n\tmargin-top: 20px;\n}\n.map {\n\tbox-shadow: 0px 0px 20px rgba(0,0,0,0.10);\n    padding: 4px;\n} "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
