import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-leaderboardlist',
  templateUrl: './leaderboardlist.component.html',
  styleUrls: ['./leaderboardlist.component.css']
})
export class LeaderboardlistComponent implements OnInit {

  form_title:string = 'Leaderboard'
  closed: boolean = true;
  alertMessage: string;
  alertClass: string;
  constructor() { }

  ngOnInit(): void {
  }

}
