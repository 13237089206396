// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .chat_padding {
    padding: 10px 15px 10px 23px;
    display: inline-grid;
} */
.chat_srch {
    margin-bottom: 0;
    border-radius: 0px;
}
.chat_srch input {
    border-radius: 0px;
    box-shadow: none !important;
    border-color: #ccc !important;
}
.chattext {
    color: #000;
}
.chat-boxs .chatdiv {
    min-height: 273px; 
    max-height: 273px; 
    width: 175px;
    margin-bottom: 10px;
}
.chat-boxs .chatdiv a {
    padding: 8px 8px!important;
}
.chat-boxs .chat_box_div {
    float: left;
    background: #ECECEC;
    width: 300px;
    max-height: 313px;
    min-height: 313px;
}
.chat-boxs .chat-box .chating {
    min-height: 200px;
    max-height: 200px;
}
.admin-chat-list {
    position: inherit;    
    box-shadow: none;
} 
.acp {
    position: fixed;
    bottom: 55px;
    left: 207px;
}
@media only screen and (max-width:767px)
{
    .acp {
        position: fixed;
        top: 6%;
        left: 6%;
    }
    .chat-boxs .chat_box_div {
        float: none;
        width: 90%;
    }
}

.chat-boxs .message-box.right-img {
    width: auto !important;
}

.text-black {
    color: #141d38;
}`, "",{"version":3,"sources":["webpack://./src/app/dd-terminal/asidebar/asidebar.component.css"],"names":[],"mappings":"AAAA;;;GAGG;AACH;IACI,gBAAgB;IAChB,kBAAkB;AACtB;AACA;IACI,kBAAkB;IAClB,2BAA2B;IAC3B,6BAA6B;AACjC;AACA;IACI,WAAW;AACf;AACA;IACI,iBAAiB;IACjB,iBAAiB;IACjB,YAAY;IACZ,mBAAmB;AACvB;AACA;IACI,0BAA0B;AAC9B;AACA;IACI,WAAW;IACX,mBAAmB;IACnB,YAAY;IACZ,iBAAiB;IACjB,iBAAiB;AACrB;AACA;IACI,iBAAiB;IACjB,iBAAiB;AACrB;AACA;IACI,iBAAiB;IACjB,gBAAgB;AACpB;AACA;IACI,eAAe;IACf,YAAY;IACZ,WAAW;AACf;AACA;;IAEI;QACI,eAAe;QACf,OAAO;QACP,QAAQ;IACZ;IACA;QACI,WAAW;QACX,UAAU;IACd;AACJ;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,cAAc;AAClB","sourcesContent":["/* .chat_padding {\n    padding: 10px 15px 10px 23px;\n    display: inline-grid;\n} */\n.chat_srch {\n    margin-bottom: 0;\n    border-radius: 0px;\n}\n.chat_srch input {\n    border-radius: 0px;\n    box-shadow: none !important;\n    border-color: #ccc !important;\n}\n.chattext {\n    color: #000;\n}\n.chat-boxs .chatdiv {\n    min-height: 273px; \n    max-height: 273px; \n    width: 175px;\n    margin-bottom: 10px;\n}\n.chat-boxs .chatdiv a {\n    padding: 8px 8px!important;\n}\n.chat-boxs .chat_box_div {\n    float: left;\n    background: #ECECEC;\n    width: 300px;\n    max-height: 313px;\n    min-height: 313px;\n}\n.chat-boxs .chat-box .chating {\n    min-height: 200px;\n    max-height: 200px;\n}\n.admin-chat-list {\n    position: inherit;    \n    box-shadow: none;\n} \n.acp {\n    position: fixed;\n    bottom: 55px;\n    left: 207px;\n}\n@media only screen and (max-width:767px)\n{\n    .acp {\n        position: fixed;\n        top: 6%;\n        left: 6%;\n    }\n    .chat-boxs .chat_box_div {\n        float: none;\n        width: 90%;\n    }\n}\n\n.chat-boxs .message-box.right-img {\n    width: auto !important;\n}\n\n.text-black {\n    color: #141d38;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
