// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.crop-image {
    border-radius: 100%;
    border: 2px solid #d8d8d8;
}
.modal-show {
    display: block;
    background-color: rgba(0,0,0,.5);
}
.dk-model-header {
    background: #141d38;
    color: #fff;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
}
.close{
    color: #fff;
}
#user-modal{
    z-index: 999999 !important;
}`, "",{"version":3,"sources":["webpack://./src/app/dd-terminal/viewuserprofile/viewuserprofile.component.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,yBAAyB;AAC7B;AACA;IACI,cAAc;IACd,gCAAgC;AACpC;AACA;IACI,mBAAmB;IACnB,WAAW;IACX,oCAAoC;IACpC,qCAAqC;AACzC;AACA;IACI,WAAW;AACf;AACA;IACI,0BAA0B;AAC9B","sourcesContent":[".crop-image {\n    border-radius: 100%;\n    border: 2px solid #d8d8d8;\n}\n.modal-show {\n    display: block;\n    background-color: rgba(0,0,0,.5);\n}\n.dk-model-header {\n    background: #141d38;\n    color: #fff;\n    border-top-left-radius: 0 !important;\n    border-top-right-radius: 0 !important;\n}\n.close{\n    color: #fff;\n}\n#user-modal{\n    z-index: 999999 !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
