// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-section .copyright {
    font-size: 12.4px;
}
.footer-section p {
	font-size: 15.5px !important;
    line-height: 22px !important;
}
.footer-section a{
	color: #fff;
}
.footer-section a:hover{
	color: #b2b5ba  !important;
}
.footer-section a:hover {
	color: #c9ddff;
}
.social-media a:hover {
	opacity: 0.7;
}
.footer-section .fli {
	margin-top: -5px;
}
.footer-section .fli a{
	font-size: 14px;
    margin-bottom: 5px !important;
    display: block;
}
/*  demo popup     */
.demo-layout{
    margin-top: 150px;
    max-width: 700px !important;
}
.dbody .form-color{
    background-color: #141D38;
}
.demo-header{
    background-color: #922C39;
}


`, "",{"version":3,"sources":["webpack://./src/app/candidate/candidatefooter/candidatefooter.component.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;AACA;CACC,4BAA4B;IACzB,4BAA4B;AAChC;AACA;CACC,WAAW;AACZ;AACA;CACC,0BAA0B;AAC3B;AACA;CACC,cAAc;AACf;AACA;CACC,YAAY;AACb;AACA;CACC,gBAAgB;AACjB;AACA;CACC,eAAe;IACZ,6BAA6B;IAC7B,cAAc;AAClB;AACA,oBAAoB;AACpB;IACI,iBAAiB;IACjB,2BAA2B;AAC/B;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,yBAAyB;AAC7B","sourcesContent":[".footer-section .copyright {\n    font-size: 12.4px;\n}\n.footer-section p {\n\tfont-size: 15.5px !important;\n    line-height: 22px !important;\n}\n.footer-section a{\n\tcolor: #fff;\n}\n.footer-section a:hover{\n\tcolor: #b2b5ba  !important;\n}\n.footer-section a:hover {\n\tcolor: #c9ddff;\n}\n.social-media a:hover {\n\topacity: 0.7;\n}\n.footer-section .fli {\n\tmargin-top: -5px;\n}\n.footer-section .fli a{\n\tfont-size: 14px;\n    margin-bottom: 5px !important;\n    display: block;\n}\n/*  demo popup     */\n.demo-layout{\n    margin-top: 150px;\n    max-width: 700px !important;\n}\n.dbody .form-color{\n    background-color: #141D38;\n}\n.demo-header{\n    background-color: #922C39;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
