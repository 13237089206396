// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.multiselect-dropdown .dropdown-list {
    max-height: 200px;
    overflow: scroll;
}
.control-btn button{
    color:#fff;
}
.td-sm{
    padding: 5px 10px !important;
}
.separater{
    border-top: 1px solid #ccc;
    padding-top: 15px;
    margin-top: 25px;
}
.dk-table table.dataTable .tidc, .dk-table .tidc {
    width: 40px !important;
}
.man-btn {
    padding: 6px 19px 6px 10px !important;
}
.fsearch {
    padding-left: 20px;
}
.s_no {
    letter-spacing: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.mdw {
    min-width: 200px;
}

@media only screen and (min-width: 1025px)  
{
    .max500 {
        max-width: 500px;
    }
}
@media only screen and (max-width: 767px)  
{
    .fsearch {
        margin-bottom: 15px;
    }
    .man-btn {
        padding: 6px 12px 6px 7px !important;
    }
}

table td {
	vertical-align: middle;
	background: #fff;
}
.tinbg{
    background-color: #ffffff;
}
.team-brdblast-child {
    border-bottom: 0;
}
.overflow-x {
    overflow-x: inherit;
}


`, "",{"version":3,"sources":["webpack://./src/app/dd-terminal/exerciseallotment/exerciseallotment.component.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,gBAAgB;AACpB;AACA;IACI,UAAU;AACd;AACA;IACI,4BAA4B;AAChC;AACA;IACI,0BAA0B;IAC1B,iBAAiB;IACjB,gBAAgB;AACpB;AACA;IACI,sBAAsB;AAC1B;AACA;IACI,qCAAqC;AACzC;AACA;IACI,kBAAkB;AACtB;AACA;IACI,mBAAmB;IACnB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;AACA;IACI,gBAAgB;AACpB;;AAEA;;IAEI;QACI,gBAAgB;IACpB;AACJ;AACA;;IAEI;QACI,mBAAmB;IACvB;IACA;QACI,oCAAoC;IACxC;AACJ;;AAEA;CACC,sBAAsB;CACtB,gBAAgB;AACjB;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,gBAAgB;AACpB;AACA;IACI,mBAAmB;AACvB","sourcesContent":[".multiselect-dropdown .dropdown-list {\n    max-height: 200px;\n    overflow: scroll;\n}\n.control-btn button{\n    color:#fff;\n}\n.td-sm{\n    padding: 5px 10px !important;\n}\n.separater{\n    border-top: 1px solid #ccc;\n    padding-top: 15px;\n    margin-top: 25px;\n}\n.dk-table table.dataTable .tidc, .dk-table .tidc {\n    width: 40px !important;\n}\n.man-btn {\n    padding: 6px 19px 6px 10px !important;\n}\n.fsearch {\n    padding-left: 20px;\n}\n.s_no {\n    letter-spacing: 9px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n.mdw {\n    min-width: 200px;\n}\n\n@media only screen and (min-width: 1025px)  \n{\n    .max500 {\n        max-width: 500px;\n    }\n}\n@media only screen and (max-width: 767px)  \n{\n    .fsearch {\n        margin-bottom: 15px;\n    }\n    .man-btn {\n        padding: 6px 12px 6px 7px !important;\n    }\n}\n\ntable td {\n\tvertical-align: middle;\n\tbackground: #fff;\n}\n.tinbg{\n    background-color: #ffffff;\n}\n.team-brdblast-child {\n    border-bottom: 0;\n}\n.overflow-x {\n    overflow-x: inherit;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
