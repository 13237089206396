import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, QueryList, ViewChildren } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpClient,HttpParams  } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BackenddbService } from '../../services/backenddb.service';
import { FrontenddbService } from '../../services/frontenddb.service';

import { LoginService } from '../../services/login.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

import { UntypedFormGroup,  UntypedFormBuilder, UntypedFormArray, FormControl, Validators  } from '@angular/forms';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import Swal from 'sweetalert2';
import {noWhitespaceValidator,jsonValidator} from '../../helper/validatefun';

import { faPlus, faFile, faUser, faEdit, faTrash, faUsers, faEye,faBars, faCamera, faCopy, faDownload, faUpload } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';

import { DatapassService } from '../../services/datapass.service';
import { Constants } from 'src/constants';
declare const activesidebar:any;

class Exercise {
  id: number;
  firstName: string;
  lastName: string;
}

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
  param:any;
}
declare var $ : any;
@Component({
  selector: 'app-challengelist',
  templateUrl: './challengelist.component.html',
  styleUrls: ['./challengelist.component.css']
})
export class ChallengelistComponent implements OnInit {

  viewProfile : Subject<any> = new Subject<any>();

  faPlus = faPlus;
	faUser = faUser;
	faEdit = faEdit;
	faTrash = faTrash;	
	faUsers = faUsers;	
	faEye = faEye;	
  faBars = faBars;
  faCamera = faCamera;
  faCopy = faCopy;
  faDownload = faDownload;
  faUpload = faUpload;
  faFile = faFile;
  public _opened: boolean = true; 
	public _toggleSidebar() {
		this._opened = !this._opened;
	} 

  /* datatable */
  @ViewChildren(DataTableDirective)
  dtElements: QueryList<DataTableDirective>;
  dtOptions: DataTables.Settings[] = [];
  // dtTrigger = new Subject();
  exercise: Exercise[];
  serverUrl = environment.baseUrl;

  /*flash message*/
  msgalert:any;
  
  /*breadcrumbs array */
  current_url_array = [];
  currentExeId:any = ''

  bg_process = [];
  bg_task = [{'task':'delete_admin_exercise'}];

  visiability_array = ['Public','Private'];
  status_array = [{label:'Active',val:'Active'},{label:'Inactive',val:'Inactive'}];
  visiability_status = '';
  status = '';
  modalConfgRef:any;

  formdata: UntypedFormGroup;
  exefrm: UntypedFormGroup;
  group_id:string = '';
  progressbar: boolean = false;
  modalfadeclass:string = "";
  error: string;
  closed: boolean = true;
  closedpopop: boolean = true;
  alertMessage: string;
  alertClass: string;
  update_by:string;
  closeResult = '';
  checkArray: UntypedFormArray;
  files:any;

  dashboard_url:string; 
  my_team:string = '';

  form_title:any = 'Challenge List';
  searchVal = '';
 
  constructor(
    private http: HttpClient,
    private router: Router,
    private BackenddbService: BackenddbService,
    private LoginService: LoginService,   
    private FrontenddbService: FrontenddbService,   
    private fb:UntypedFormBuilder,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private ds: DatapassService
    ) {
        this.update_by = this.LoginService.getUserId();
        this.group_id = this.LoginService.getLoginGroup();
        this.dashboard_url = this.LoginService.getDashboardUrl();
        this.getFlashMessage();
    }

		ngOnInit() {
      activesidebar(); 
   		this.createChallengeTable();
		this.current_url_array = [
			{'slug':"",'label':'Exercise List'}
		  ];
	}

  createChallengeTable()
  {
       /* user data table */
       const that = this;
       this.dtOptions[0] = {
         dom: '<"top">tr<"bottom"ilp><"clear">',
         pagingType: 'full_numbers',
         pageLength: 10,
         serverSide: true,
         processing: true,
         order: [[ 0, "desc" ]],
         ajax: (dataTablesParameters: any, callback:any ) => {
   
           let params = new HttpParams();
           let startNumber: any;
   
           startNumber = dataTablesParameters.start;
           if (startNumber != 0) {
             startNumber = startNumber + 1
           } else {
             startNumber = startNumber;
           }
           params = params.append("start", startNumber);
           params = params.append("length", dataTablesParameters.length);
           params = params.append("draw", dataTablesParameters.draw);
           params = params.append("search", this.searchVal);
           params = params.append("order_col", dataTablesParameters.order[0].column);
           params = params.append("order_type", dataTablesParameters.order[0].dir);        
           params = params.append("status", this.status);
           
          //  if(this.group_id=='2')
          //  {
          //   params = params.append("created_by", this.update_by);
          //  }else
          //  {
          //   params = params.append("created_by",'');
          //  }
   
           let param = params.toString();
           that.http
             .post<DataTablesResponse>(
               this.serverUrl+'datatable-api/get-challenge-list',
               params, {}
             ).subscribe(resp => {
               that.exercise = resp.data;
               callback({
                 recordsTotal: resp.recordsTotal,
                 recordsFiltered: resp.recordsFiltered,
                 data: []
               });
             });
         },
         columns: [{ data: 's_no' }, { data: 'name' }, { data: 'created_by' }, { data: 'last_datetime' }]
       };
  }

  datatableSearch(event){
    this.searchVal  = event.target.value; 
    this.rerender_datatable('team-table');
  }

  ngAfterViewInit(): void {
   //this.dtTrigger.next(true);
  }

  ngOnDestroy(): void {
    //this.dtTrigger.unsubscribe();
  }

  rerender_datatable(datatableName:any) {
    this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
      dtElement.dtInstance.then((dtInstance: any) => {
        if(dtInstance.table().node().id == datatableName) {
           dtInstance.draw();
        }
      });
    });
  }

  changeStatus(event:any,type:any)
  {
    if(type=='status')
    {
      this.status = event.target.value;
    }
    this.rerender_datatable('team-table');
  }
  
  deleteChallenge(id:any)
  {
    Swal.fire({
      title: 'Are you sure?',
      text: Constants.ALERT_DEL,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'OK',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.value) {
          this.ds.Loader(true)
          const formData = new FormData();
          /* here set submitted data in formData object array */
          formData.append('id', id);
          this.BackenddbService.postData('challenge-api/delete-challenge',formData).subscribe(
            res => {
              this.ds.Loader(false)
              if(res.status == 'success') {
                 this.rerender_datatable('team-table');
                 //Swal.fire('',res.message,'success');
              }else {
                 Swal.fire('',res.message,'warning');
              }
            },
            error => this.error = error
            );
            }
    })
  }

  redirect(strurl:any)
  {
    this.router.navigate([strurl]);
  }

  preloadData()
  {
     this.ds.sendData('get_permission');
  }  
  
  set_permission(data:any)
  {
    if(data.permission.create_exercise=='Denied')
    {
        this.router.navigate([this.dashboard_url+'dashboard']);
    }
  }

  getFlashMessage()
  {
    this.msgalert = this.LoginService.getflashMessage();
    if (typeof this.msgalert.type != "undefined")
    {
      this.alertClass = this.msgalert.type;
      this.alertMessage = this.msgalert.message;
      this.closed = false;
    }
  }

  viewUserProfile(user_id:any)
  {
    this.viewProfile.next(user_id);
  }

 
  }