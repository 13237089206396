// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `span.invite-email {
    padding: 5px;
    background-color: #ccc;
    border-radius: 5px;
    margin-right: 5px;
    font-size: 14px;
    display: inline-block;
}
button.btn.btn-sm.btn-danger {
    font-size: 10px;
    padding: 0px 4px;
}
.autocomplete-overlay {
    display: none;
}
.dk-model-box .mtable-height {
    overflow: unset !important;
}`, "",{"version":3,"sources":["webpack://./src/app/schedule/schedule.component.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;IAClB,iBAAiB;IACjB,eAAe;IACf,qBAAqB;AACzB;AACA;IACI,eAAe;IACf,gBAAgB;AACpB;AACA;IACI,aAAa;AACjB;AACA;IACI,0BAA0B;AAC9B","sourcesContent":["span.invite-email {\n    padding: 5px;\n    background-color: #ccc;\n    border-radius: 5px;\n    margin-right: 5px;\n    font-size: 14px;\n    display: inline-block;\n}\nbutton.btn.btn-sm.btn-danger {\n    font-size: 10px;\n    padding: 0px 4px;\n}\n.autocomplete-overlay {\n    display: none;\n}\n.dk-model-box .mtable-height {\n    overflow: unset !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
