import { Component, OnInit } from '@angular/core';
import { LoginService } from '../services/login.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

import Swal from 'sweetalert2';
import { DatapassService } from '../services/datapass.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  server_url:string;
  site_url:string;
  popup:boolean=false;
  
  constructor(private loginService: LoginService, private modalService: NgbModal,
    private ds: DatapassService) { 
    this.server_url = this.loginService.getServerUrl();
    this.site_url = this.loginService.getSiteUrl();
  }

  banner_slider: OwlOptions = {
    autoplay: true,
    loop: true,
    mouseDrag: true, 
    touchDrag: true,
    pullDrag: false,
    dots: true,
    nav: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      768: {
        items: 1
      }
    }
  }

  clients: OwlOptions = {
    loop: true,
    mouseDrag: true, 
    touchDrag: true,
    pullDrag: false,
    dots: true,
    nav: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      768: {
        items: 2
      }
    }
  }

  partners: OwlOptions = {
    loop: true,
    mouseDrag: true, 
    touchDrag: true,
    pullDrag: false,
    dots: true,
    nav: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 3
      },
      768: {
        items: 3
      }
    }
  }

  testimonials: OwlOptions = {
    loop: true,
    mouseDrag: true, 
    touchDrag: true,
    pullDrag: false,
    dots: true,
    nav: false,
    navSpeed: 700,
    navText: ['', ''],
    items: 4,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      768: {
        items: 3
      },
      900: {
        items: 4
      }
    }
  }

  ngOnInit(): void {
  }

  openmodal()
  {
    var array = ['open_demo_model','open'];
    this.ds.sendData(array);
  }
	

}
