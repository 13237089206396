// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ` 

.search-section .search-exer {
    margin-top:5px;
    padding-left: 0px;
    margin-bottom: 5px;
 }
 `, "",{"version":3,"sources":["webpack://./src/app/candidate/labusages/labusages.component.css"],"names":[],"mappings":";;AAEA;IACI,cAAc;IACd,iBAAiB;IACjB,kBAAkB;CACrB","sourcesContent":[" \n\n.search-section .search-exer {\n    margin-top:5px;\n    padding-left: 0px;\n    margin-bottom: 5px;\n }\n "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
