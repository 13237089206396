import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, QueryList, ViewChildren } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpClient,HttpParams  } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BackenddbService } from '../../services/backenddb.service';
import { FrontenddbService } from '../../services/frontenddb.service';

import { LoginService } from '../../services/login.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

import { UntypedFormGroup,  UntypedFormBuilder, UntypedFormArray, FormControl, Validators  } from '@angular/forms';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import Swal from 'sweetalert2';
import {noWhitespaceValidator,jsonValidator} from '../../helper/validatefun';

import { faUser, faEdit, faPlus, faTrash, faUsers, faEye,faBars, faCamera, faCopy, faDownload, faUpload } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';

import { DatapassService } from '../../services/datapass.service';
import { Constants } from 'src/constants';
declare const activesidebar:any;

class Exercise {
  id: number;
  firstName: string;
  lastName: string;
}

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
  param:any;
}
declare var $ : any;
@Component({
  selector: 'app-tempexerciselist',
  templateUrl: './tempexerciselist.component.html',
  styleUrls: ['./tempexerciselist.component.css']
})
export class TempexerciselistComponent implements OnInit {

  viewProfile : Subject<any> = new Subject<any>();

  faPlus = faPlus;
	faUser = faUser;
	faEdit = faEdit;
	faTrash = faTrash;	
	faUsers = faUsers;	
	faEye = faEye;	
  faBars = faBars;
  faCamera = faCamera;
  faCopy = faCopy;
  faDownload = faDownload;
  faUpload = faUpload;
  public _opened: boolean = true; 
	public _toggleSidebar() {
		this._opened = !this._opened;
	} 

  /* datatable */
  @ViewChildren(DataTableDirective)
  dtElements: QueryList<DataTableDirective>;
  dtOptions: DataTables.Settings[] = [];
  //dtTrigger = new Subject();
  exercise: Exercise[];
  serverUrl = environment.baseUrl;

  /*flash message*/
  msgalert:any;
  
  /*breadcrumbs array */
  current_url_array = [
    {'slug':"",'label':'Exercise Builder'}
    ];
  currentExeId:any = ''

  bg_process = [];
  bg_task = [{'task':'prepare_admin_infra'},{'task':'delete_admin_infra'}];

  visiability_array = ['Public','Private'];
  status_array = [{label:'Pending',val:'pending'},{label:'Success',val:'success'},{label:'Processing',val:'processing'}];
  visiability_status = '';
  status = '';
  modalConfgRef:any;

  formdata: UntypedFormGroup;
  exefrm: UntypedFormGroup;
  group_id:string = '';
  progressbar: boolean = false;
  modalfadeclass:string = "";
  error: string;
  closed: boolean = true;
  closedpopop: boolean = true;
  alertMessage: string;
  alertClass: string;
  update_by:string;
  closeResult = '';
  checkArray: UntypedFormArray;
  importFrm: UntypedFormGroup;
  files:any;

  dashboard_url:string; 
  my_team:string = '';

  form_title:any = 'Exercise Builder';

  searchVal = '';

  error_messages = {
     'exeImport': [
      { type: 'required', message: 'JSON file is required' },
    ]
  } 
  
  constructor(
    private http: HttpClient,
    private router: Router,
    private BackenddbService: BackenddbService,
    private LoginService: LoginService,   
    private FrontenddbService: FrontenddbService,   
    private fb:UntypedFormBuilder,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private ds: DatapassService
    ) {
        this.update_by = this.LoginService.getUserId();
        this.group_id = this.LoginService.getLoginGroup();
        this.dashboard_url = this.LoginService.getDashboardUrl();
        this.getFlashMessage();
    }

		ngOnInit() {
      activesidebar(); 
      this.ds.getData().subscribe(x => { 
        if(x[0]=='set_process_array')
        {
            this.set_process_array(x[1]);
        }
      });
		this.createTempExeTable();
	}

  createTempExeTable()
  {
       /* user data table */
       const that = this;
       this.dtOptions[0] = {
         dom: '<"top">tr<"bottom"ilp><"clear">',
         pagingType: 'full_numbers',
         pageLength: 10,
         serverSide: true,
         processing: true,
         order: [[ 0, "desc" ]],
         ajax: (dataTablesParameters: any, callback:any ) => {
   
           let params = new HttpParams();
           let startNumber: any;
   
           startNumber = dataTablesParameters.start;
           if (startNumber != 0) {
             startNumber = startNumber + 1
           } else {
             startNumber = startNumber;
           }
           params = params.append("start", startNumber);
           params = params.append("length", dataTablesParameters.length);
           params = params.append("draw", dataTablesParameters.draw);
           params = params.append("search", this.searchVal);
           params = params.append("order_col", dataTablesParameters.order[0].column);
           params = params.append("order_type", dataTablesParameters.order[0].dir);        
           params = params.append("status", this.status);
           
           if(this.group_id=='2')
           {
            params = params.append("created_by", this.update_by);
           }else
           {
            params = params.append("created_by",'');
           }
   
           let param = params.toString();
           that.http
             .post<DataTablesResponse>(
               this.serverUrl+'datatable-api/get-temp-exe-list',
               params, {}
             ).subscribe(resp => {
               that.exercise = resp.data;
               callback({
                 recordsTotal: resp.recordsTotal,
                 recordsFiltered: resp.recordsFiltered,
                 data: []
               });
             });
         },
         columns: [{ data: 's_no' }, { data: 'unique_id' },{ data: 'exe_name' }, { data: 'name' }, { data: 'created_by' }, { data: 'status' }, { data: 'last_datetime' }]
       };
  }

  datatableSearch(event){
    this.searchVal  = event.target.value; 
    this.rerender_datatable('team-table');
  }

  ngAfterViewInit(): void {
   //this.dtTrigger.next(true);
  }

  ngOnDestroy(): void {
   // this.dtTrigger.unsubscribe();
  }

  rerender_datatable(datatableName:any) {
    this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
      dtElement.dtInstance.then((dtInstance: any) => {
        if(dtInstance.table().node().id == datatableName) {
           dtInstance.draw();
        }
      });
    });
  }

  changeStatus(event:any,type:any)
  {
    if(type=='status')
    {
      this.status = event.target.value;
    }
    this.rerender_datatable('team-table');
  }


  deleteTempExercise(uniqueId:any)
  {
    Swal.fire({
      title: 'Are you sure?',
      text: Constants.DEL_TEMP_INFRA_AND_DATA,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'OK',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.value) {
        const formData = new FormData();
        formData.append('update_by', this.update_by);  
        formData.append('process','delete_admin_infra');  
        formData.append('exe_unique_id', uniqueId);  
        formData.append('asset_unique_id', uniqueId);  
        formData.append('label','Deleting infra('+uniqueId+')'); 
        formData.append('delete_all','Yes');  
        this.BackenddbService.rabbitmqSubmitProcess(formData).subscribe(
          res => {
            this.ds.Loader(false);
            if(res.status == 'success')
            {
              this.ds.sendData('background_process');
            }else 
            {
                this.alertMessage = Constants.ERROR;
                this.alertClass = 'danger';
                this.closed = false;
            }
          }
        );
      }
    })
  }
  
  redirect(strurl:any)
  {
    this.router.navigate([strurl]);
  }

  set_process_array(data:any)
  {
    this.bg_process = data;
    data.forEach((dobj:any, dataindex:any) => { 
      var result = this.bg_task.find(bgt => bgt.task == dobj.process_type);
      if(typeof(result) !='undefined' && (dobj.status=='success' || dobj.status=='fail'))
      {
         this.rerender_datatable('team-table');
      }
    });  
  }

  preloadData()
  {
     this.ds.sendData('background_process');
     this.ds.sendData('get_permission');
  }  
  
  // set_permission(data:any)
  // {
  //   if(data.permission.create_exercise=='Denied')
  //   {
  //       this.router.navigate([this.dashboard_url+'dashboard']);
  //   }
  // }

  refreshMainTable() {
    this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
      dtElement.dtInstance.then((dtInstance: any) => {
      });
    })
  }


  getFlashMessage()
  {
    this.msgalert = this.LoginService.getflashMessage();
    if (typeof this.msgalert.type != "undefined")
    {
      this.alertClass = this.msgalert.type;
      this.alertMessage = this.msgalert.message;
      this.closed = false;
    }
  }


  viewUserProfile(user_id:any)
  {
    this.viewProfile.next(user_id);
  }

 
  }