// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progressbar-area {
    position: fixed;
    padding-top: 25%;
    z-index: 9999999;
    top: 0px;
    height: 100%;
    background-color: rgba(0,0,0,.7);
}
.inner-area {
    margin: auto;
}
.inner-area p {
    text-align: center;
    color: #fff;
}
.bggray-thankyou {    
    background: #000; 
    color: #cacaca;  
    height: 87vh;
    display: flex;      
    display: -ms-flexbox;    
    flex-direction: column;    
    justify-content: center;
    align-items: center;  
}
.bggray-thankyou .thankyou-msg{    
    background: #363636; 
    color: #fff;
    padding: 25px 35px 15px; 
    width: 500px;
    text-align: center;
}
hr {
    border-top: 1px solid rgb(160 160 160 / 45%);
}

@media only screen and (max-width: 768px)
{
    .bggray-thankyou .thankyou-msg{  
        padding: 25px 35px 15px; 
        width: auto;
    }
    .bggray-thankyou { 
        padding: 15px;
    }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation:portrait) 
{
    .bggray-thankyou .thankyou-msg{  
        width: 500px;
    }
}`, "",{"version":3,"sources":["webpack://./src/app/registration/verify/verify.component.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,gBAAgB;IAChB,gBAAgB;IAChB,QAAQ;IACR,YAAY;IACZ,gCAAgC;AACpC;AACA;IACI,YAAY;AAChB;AACA;IACI,kBAAkB;IAClB,WAAW;AACf;AACA;IACI,gBAAgB;IAChB,cAAc;IACd,YAAY;IACZ,aAAa;IACb,oBAAoB;IACpB,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;AACvB;AACA;IACI,mBAAmB;IACnB,WAAW;IACX,uBAAuB;IACvB,YAAY;IACZ,kBAAkB;AACtB;AACA;IACI,4CAA4C;AAChD;;AAEA;;IAEI;QACI,uBAAuB;QACvB,WAAW;IACf;IACA;QACI,aAAa;IACjB;AACJ;AACA;;IAEI;QACI,YAAY;IAChB;AACJ","sourcesContent":[".progressbar-area {\n    position: fixed;\n    padding-top: 25%;\n    z-index: 9999999;\n    top: 0px;\n    height: 100%;\n    background-color: rgba(0,0,0,.7);\n}\n.inner-area {\n    margin: auto;\n}\n.inner-area p {\n    text-align: center;\n    color: #fff;\n}\n.bggray-thankyou {    \n    background: #000; \n    color: #cacaca;  \n    height: 87vh;\n    display: flex;      \n    display: -ms-flexbox;    \n    flex-direction: column;    \n    justify-content: center;\n    align-items: center;  \n}\n.bggray-thankyou .thankyou-msg{    \n    background: #363636; \n    color: #fff;\n    padding: 25px 35px 15px; \n    width: 500px;\n    text-align: center;\n}\nhr {\n    border-top: 1px solid rgb(160 160 160 / 45%);\n}\n\n@media only screen and (max-width: 768px)\n{\n    .bggray-thankyou .thankyou-msg{  \n        padding: 25px 35px 15px; \n        width: auto;\n    }\n    .bggray-thankyou { \n        padding: 15px;\n    }\n}\n@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation:portrait) \n{\n    .bggray-thankyou .thankyou-msg{  \n        width: 500px;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
