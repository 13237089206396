import { Component, OnInit,ViewChild,Input,ViewEncapsulation,QueryList,ViewChildren } from '@angular/core';
import { HttpClient,HttpParams  } from '@angular/common/http';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';

import { UntypedFormGroup, UntypedFormBuilder  } from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import Swal from 'sweetalert2';
import { faUser, faUsers ,faEdit,faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { faCrown } from '@fortawesome/free-solid-svg-icons'; 
import { LoginService } from '../services/login.service';
import { BackenddbService } from '../services/backenddb.service';
import { DatapassService } from '../services/datapass.service';
import { EditchallengeComponent } from '../dd-terminal/editchallenge/editchallenge.component';

import {numberToRankText} from '../helper/custom';


class myScoreTable {
  id: number;
  firstName: string;
  lastName: string;
}

class MyScoreResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
  param:any;
}

@Component({
  selector: 'app-myscoreboardlist',
  templateUrl: './myscoreboardlist.component.html',
  styleUrls: ['./myscoreboardlist.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class MyscoreboardlistComponent implements OnInit {
  @Input() side: string = 'front';
  @ViewChild(EditchallengeComponent) editChallComp;

  faEdit = faEdit
  closeResult: string;
  faCrown = faCrown
  faUser = faUser
  faUsers = faUsers
  faCheck = faCheck
  faTimes = faTimes

  @ViewChildren(DataTableDirective)
  dtElements: QueryList<DataTableDirective>;
  dtOptions: DataTables.Settings[] = [];
  // dtTrigger = new Subject();
  // dtMyScoreTrigger = new Subject();
  searchVal:string = ''
  numberToRankText:any

  quesForm: UntypedFormGroup;
  groupId:string = '';
  closed: boolean = true;
  userId:string = ''
  challenges:any = []
  myScoreBoard: myScoreTable[]

  myChallenges:any = []
  serverUrl:string = environment.baseUrl;
  status:string = ''
  totalScore:number = 0
  totalObtain:number = 0
	active = 1;
  initMyScore:boolean = false
  title:string = ''
  task:any = []
  correct:any = []
  attendId:string = ''
  currentChallId:string = ''
  currentImg:string = ''
  challengeName:string = ''
  answer:any = []
  options: any = []

  constructor(
    private http: HttpClient,
    private modalService: NgbModal,
    private loginService: LoginService,
    private BackenddbService: BackenddbService,
    private ds: DatapassService, 
    private fb:UntypedFormBuilder,
    ) 
    {
      this.userId = this.loginService.getUserId(); 
      this.groupId = this.loginService.getLoginGroup();
      this.numberToRankText = numberToRankText 
    }

  ngOnInit(): void {
    this.createMyScoreBoard()
  }

  //This function call when attendees answer updated it will call by editChallengeComponent
  reloadData()
  {
      this.renderDataTable('my-score-table');
  }
  //Here call editChallengeComponent function for show attendees challenge answer for update
  openEditModal(id:any)
  {
      this.editChallComp.openEditChallModal(id)
  }  
  createMyScoreBoard()
  {
       /* user data table */
       const that = this;
       this.dtOptions[0] = {
         dom: '<"top">tr<"bottom"ilp><"clear">',
         pagingType: 'full_numbers',
         pageLength: 10,
         serverSide: true,
         processing: true,
         order: [[ 0, "asc" ]],
         ajax: (dataTablesParameters: any, callback:any ) => {
           let params = new HttpParams();
           let startNumber: any;
           startNumber = dataTablesParameters.start;
           if (startNumber != 0) {
             startNumber = startNumber + 1
           } else {
             startNumber = startNumber;
           }
           params = params.append("start", startNumber);
           params = params.append("length", dataTablesParameters.length);
           params = params.append("draw", dataTablesParameters.draw);
           params = params.append("search", this.searchVal);
           params = params.append("order_col", dataTablesParameters.order[0].column);
           params = params.append("order_type", dataTablesParameters.order[0].dir);        
           params = params.append("status", this.status);
           let param = params.toString();
           that.http
             .post<MyScoreResponse>(
               this.serverUrl+'datatable-api/get-myscore-list',
               params, {}
             ).subscribe(resp => {
               that.myScoreBoard = resp.data;
               callback({
                 recordsTotal: resp.recordsTotal,
                 recordsFiltered: resp.recordsFiltered,
                 data: []
               });
             });
         },
         columns: [{ data: 's_no' }, { data: 'created_by' }, { data: 'last_datetime' }]
       };
  }

  datatableSearch(event:any,tableId:any){
    this.searchVal = event.target.value; 
    this.renderDataTable(tableId);
  }

  ngAfterViewInit(): void {
  // this.dtMyScoreTrigger.next(true);
  }

  ngOnDestroy(): void {
  //  this.dtMyScoreTrigger.unsubscribe();
  }

  renderDataTable(datatableName:any) {
    this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
      dtElement.dtInstance.then((dtInstance: any) => {
        if(dtInstance.table().node().id == datatableName) {
           dtInstance.draw();
        }
      });
    });
  }
}

