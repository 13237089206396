// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-message {
    color: red;
    display: block;
    text-align: left;
    font-family: 'OpenSans-SemiBold', sans-serif;
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,cAAc;IACd,gBAAgB;IAChB,4CAA4C;AAChD","sourcesContent":[".error-message {\n    color: red;\n    display: block;\n    text-align: left;\n    font-family: 'OpenSans-SemiBold', sans-serif;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
